const version = "?v=0.02"
// bottom
export const url_bottom_address = "https://qn.notionbase.com/notion/bottom/file_address.png"
export const url_bottom_copyright = "https://qn.notionbase.com/notion/bottom/file_copyright.png"
export const url_bottom_email = "https://qn.notionbase.com/notion/bottom/file_email.png"
export const url_bottom_icon_address = "https://qn.notionbase.com/notion/bottom/file_icon-address.png"
export const url_bottom_icon_phone = "https://qn.notionbase.com/notion/bottom/file_icon-phone.png"
export const url_bottom_redbook = "https://qn.notionbase.com/notion/bottom/file_redbook.png"
export const url_bottom_social = "https://qn.notionbase.com/notion/bottom/file_social.png"
export const url_bottom_wechat = "https://qn.notionbase.com/notion/bottom/file_wechat.png"

// case
export const url_case_default_phone = "https://qn.notionbase.com/notion/case/default-phone.gif"
export const url_case_default = "https://qn.notionbase.com/notion/case/default.gif"
export const url_case_dickies_phone = "https://qn.notionbase.com/notionbase/case/dickies-phone.gif"
export const url_case_dickies = "https://qn.notionbase.com/notionbase/case/dickies.gif"
export const url_case_dior_phone = "https://qn.notionbase.com/notionbase/case/dior-phone.gif"
export const url_case_dior = "https://qn.notionbase.com/notionbase/case/dior.gif"
export const url_case_GM_phone = "https://qn.notionbase.com/notionbase/case/GM-phone.gif"
export const url_case_GM = "https://qn.notionbase.com/notionbase/case/GM.gif"
export const url_case_Martell_phone = "https://qn.notionbase.com/notionbase/case/Martell-phone.gif"
export const url_case_Martell = "https://qn.notionbase.com/notionbase/case/Martell.gif"
export const url_case_Tiffany_phone = "https://qn.notionbase.com/notionbase/case/Tiffany-phone.gif"
export const url_case_Tiffany = "https://qn.notionbase.com/notionbase/case/Tiffany.gif"

export const url_case_vw = "https://qn.notionbase.com/notionbase/case/vw.gif" + version
export const url_case_vw_phone = "https://qn.notionbase.com/notionbase/case/vw-phone.gif"
export const url_case_chaumet = "https://qn.notionbase.com/notionbase/case/chaumet.gif"
export const url_case_chaumet_phone = "https://qn.notionbase.com/notionbase/case/chaumet-phone.gif"
export const url_case_delvaux = "https://qn.notionbase.com/notionbase/case/delvaux.gif"
export const url_case_delvaux_phone = "https://qn.notionbase.com/notionbase/case/delvaux-phone.gif"
export const url_case_dg = "https://qn.notionbase.com/notionbase/case/dg.gif"
export const url_case_dg_phone = "https://qn.notionbase.com/notionbase/case/dg-phone.gif"
export const url_case_valorant = "https://qn.notionbase.com/notionbase/case/valorant.gif"
export const url_case_valorant_phone = "https://qn.notionbase.com/notionbase/case/valorant-phone.gif"
export const url_case_hw = "https://qn.notionbase.com/notionbase/case/hw.gif"
export const url_case_hw_phone = "https://qn.notionbase.com/notionbase/case/hw-phone.gif"

// common
export const url_common_arrow_70 = "https://qn.notionbase.com/notion/common/file_arrow-70.png"
export const url_common_arrow_100 = "https://qn.notionbase.com/notion/common/file_arrow-100.png"
export const url_common_arrow_right_top = "https://qn.notionbase.com/notion/common/file_arrow-right-top.png"
export const url_common_arrow_right = "https://qn.notionbase.com/notion/common/file_arrow-right.png"
export const url_common_btn_apply_def = "https://qn.notionbase.com/notion/common/file_btn-apply-def.png"

export const url_common_btn_apply = "https://qn.notionbase.com/notion/common/file_btn-apply.png"
export const url_common_btn_back = "https://qn.notionbase.com/notion/common/file_btn-back.png"
export const url_common_btn_close = "https://qn.notionbase.com/notion/common/file_btn-close.png"
export const url_common_btn_confirm_def = "https://qn.notionbase.com/notion/common/file_btn-confirm-def.png"
export const url_common_btn_confirm = "https://qn.notionbase.com/notion/common/file_btn-confirm.png"

export const url_common_btn_contact_sel = "https://qn.notionbase.com/notion/common/file_btn-contact-sel.png"
export const url_common_btn_contact = "https://qn.notionbase.com/notion/common/file_btn-contact.png"
export const url_common_btn_enter_base_def = "https://qn.notionbase.com/notion/common/file_btn-enter-base-def.png"
export const url_common_btn_enter_base_sel = "https://qn.notionbase.com/notion/common/file_btn-enter-base-sel.png"
export const url_common_btn_enter_default = "https://qn.notionbase.com/notion/common/file_btn-enter-default.png"

export const url_common_btn_enter_select = "https://qn.notionbase.com/notion/common/file_btn-enter-select.png"
export const url_common_btn_jobs_sel = "https://qn.notionbase.com/notion/common/file_btn-jobs-sel.png"
export const url_common_btn_jobs = "https://qn.notionbase.com/notion/common/file_btn-jobs.png"
export const url_common_btn_menu = "https://qn.notionbase.com/notion/common/file_btn-menu.png"
export const url_common_btn_replay_select = "https://qn.notionbase.com/notionbase/common/btn-replay-select.png"

export const url_common_btn_replay = "https://qn.notionbase.com/notionbase/common/btn-replay.png"
export const url_common_btn_service_sel = "https://qn.notionbase.com/notion/common/file_btn-service-sel.png"
export const url_common_btn_service = "https://qn.notionbase.com/notion/common/file_btn-service.png"
export const url_common_btn_skip = "https://qn.notionbase.com/notion/common/file_btn-skip.png"
export const url_common_btn_start_exploring_def = "https://qn.notionbase.com/notion/common/file_btn-start-exploring-def.png"

export const url_common_btn_start_exploring_sel = "https://qn.notionbase.com/notion/common/file_btn-start-exploring-sel.png"
export const url_common_btn_submit_def = "https://qn.notionbase.com/notion/common/file_btn-submit-def.png"
export const url_common_btn_submit_sel = "https://qn.notionbase.com/notion/common/file_btn-submit-sel.png"
export const url_common_china = "https://qn.notionbase.com/notion/common/file_china.png"
export const url_common_icon_add = "https://qn.notionbase.com/notion/common/file_icon-add.png"

export const url_common_icon_logo = "https://qn.notionbase.com/notionbase/common/notion-logo-3.gif"
export const url_common_korea = "https://qn.notionbase.com/notion/common/file_korea.png"
export const url_common_learn_more = "https://qn.notionbase.com/notion/common/file_learn_more.png"
export const url_common_notionbase = "https://qn.notionbase.com/notion/common/file_notionbase.png"
export const url_common_singapore = "https://qn.notionbase.com/notion/common/file_singapore.png"
export const url_common_view_more = "https://qn.notionbase.com/notion/common/file_view_more.png"

export const url_common_next_chpt = "https://qn.notionbase.com/notionbase/common/next-chpt.png"
export const url_common_next_chpt_sel = "https://qn.notionbase.com/notionbase/common/next-chpt-select.png"

export const url_common_arrow_next = "https://qn.notionbase.com/notion/common/arrow-next.gif"

// index
export const url_index_astronaut = "https://qn.notionbase.com/notion/index/file_astronaut.png"
export const url_index_bg_page_6_small = "https://qn.notionbase.com/notion/index/file_bg-page-6-small.png"
export const url_index_bg_page_6 = "https://qn.notionbase.com/notionbase/index/bg-page-06-1.png"
export const url_index_bg_page_7 = "https://qn.notionbase.com/notion/index/file_bg-page-7.jpg"
export const url_index_bg_page_8 = "https://qn.notionbase.com/notion/index/file_bg-page-8.jpg"
export const url_index_computer = "https://qn.notionbase.com/notion/index/file_computer.png"

export const url_index_phone = "https://qn.notionbase.com/notion/index/file_phone.png"
export const url_index_spaceman = "https://qn.notionbase.com/notion/index/file_spaceman.png"
export const url_index_test_footprints = "https://qn.notionbase.com/notion/index/file_test-footprints.png"
export const url_index_test_touch = "https://qn.notionbase.com/notion/index/file_test-touch.png"
export const url_index_top_black = "https://qn.notionbase.com/notion/index/file_top-black.png"

// logo
export const url_logo_bottom = "https://qn.notionbase.com/notion/logo/file_bottom.png"
export const url_logo_thanks = "https://qn.notionbase.com/notion/logo/file_thanks.png"

// menu
export const url_menu_client_gif = "https://qn.notionbase.com/notionbase/menu/client.gif"
export const url_menu_home_gif = "https://qn.notionbase.com/notionbase/menu/home.gif"
export const url_menu_contact_gif = "https://qn.notionbase.com/notionbase/menu/contact.gif"
export const url_menu_project_gif = "https://qn.notionbase.com/notionbase/menu/project.gif"

export const url_menu_client = "https://qn.notionbase.com/notion/menu/file_client.png"
export const url_menu_home = "https://qn.notionbase.com/notion/menu/file_home.png"
export const url_menu_contact = "https://qn.notionbase.com/notion/menu/file_contact.png"
export const url_menu_project = "https://qn.notionbase.com/notion/menu/file_project.png"
export const url_menu_icon_mute = "https://qn.notionbase.com/notion/menu/file_icon-mute.png"
export const url_menu_icon_volume = "https://qn.notionbase.com/notion/menu/file_icon-volume.png"

// social
export const url_social_redbook = "https://qn.notionbase.com/notion/social/file_redbook.png"
export const url_social_linkedin = "https://qn.notionbase.com/notion/social/file_linkedin.png"
export const url_social_wechat = "https://qn.notionbase.com/notion/social/file_wechat.png"