<template>
	<div class="container">
		<swiper ref="pageSwiper" class="page-swiper" :options="pageSwiperOption">
			<swiper-slide class="page-swiper-slide">
				<div class="page6">
					<img v-if="screenFlag == 3" class="page6-case-select" :src="selectCasePhoneSrc" />
					<img v-else class="page6-case-select" :src="selectCaseSrc" />
					<img v-if="screenFlag == 3" class="page6-bg" :src="bgPage6SmallSrc" />
					<img v-else class="page6-bg" :src="bgPage6Src" />
					<img class="page6-top-black" :src="topBlackSrc" mode="widthFix" />
					<img :class="firstIndex >= 0 || isBegin2 == false ? 'page6-title fadeIn1SDelay1SEffect':'page6-title-1'"
						:src="testFootprintsSrc"></img>
					<!-- <img v-if="screenFlag != 3" class="arrow-next" :src="arrowNextSrc" mode="widthFix" @click="nextPage(0)"/> -->
					<div class="case-scroll-view">
						<div v-if="screenFlag != 3" class="case-line-group" :style="{'width': width * 0.1208 + 'px'}" 
						:class="firstIndex >= 0 || isBegin2 == false ? 'page6-line fadeIn1SDelay1SEffect':'page6-line-1'">
							<div v-for="(item, index) in lineList" :key="index" class="line-group" @click="caseSwiperChange(index)">
								<div :class="index == caseIndex? 'case-line-hover' : 'case-line'"></div>
							</div>
						</div>
						</div>
					<img v-if="screenFlag != 3" class="page6-btn-skip-wrap" :src="btnNextChptSrc"
						v-on:mouseover="onMouseNextOver()" v-on:mouseout="onMouseNextOut()" @click="onBtnNext" />
				</div>
			</swiper-slide>
			<swiper-slide class="page-swiper-slide">
				<div class="page7">
					<!-- <img v-if="screenFlag != 3" class="arrow-next" :src="arrowNextSrc" mode="widthFix" @click="nextPage(1)"/> -->
					<img class="page7-bg" :src="bgPage7Src" />
					<div id="page7Content" class="page7-content">
						<div v-if="screenFlag == 3" class="page7-thanks-img"
							:style="{'width': width * 0.86 + 'px', 'height': height * 0.82 + width * 0.64 * 8126 / 2614 + 'px'}">
							<img :src="thanksSrc" />
						</div>
						<div v-else class="page7-thanks-img"
							:style="{'width': width * 0.64 + 'px', 'height': height * 0.82 + width * 0.64 * 8126 / 2614 + 'px'}">
							<img :src="thanksSrc" />
						</div>
						<div v-if="screenFlag == 3" class="page7-thanks-bottom"
							:style="{'width': width * 0.86 + 'px', 'height': height * 0.82 + 'px'}">
							<img :src="bottomSrc" />
						</div>
						<div v-else class="page7-thanks-bottom"
							:style="{'width': width * 0.64 + 'px', 'height': height * 0.82 + 'px'}">
							<img :src="bottomSrc" />
						</div>
					</div>
					<img v-if="!isClickRefresh" class="page7-btn-replay" :style="{'opacity': btnReplayOpacity}"
						:src="btnReplaySrc" v-on:mouseover="onMouseReplayOver()" v-on:mouseout="onMouseReplayOut()"
						@click="onBtnReplay" />
					<div v-if="!isClickRefresh" class="page7-btn-skip-wrap"
						:style="{'height': width * (screenFlag == 3 ? 0.15 : 0.078) * 80 / 300 + 'px'}">
						<div :class="swiperIndex == 1 ? 'skipBgEffect' : ''" class="page7-btn-skip"
							:style="{'opacity': btnSkipOpacity}" v-on:mouseover="onMouseSkipOver()"
							v-on:mouseout="onMouseSkipOut()" @click="onBtnSkipToEnd">
							<img :src="btnSkipSrc" />
						</div>
					</div>
				</div>
			</swiper-slide>
			<swiper-slide class="page-swiper-slide">
				<div id="page8" class="page8" @click="cancelPop($event)">
					<img class="page8-bg" :src="bgPage8Src" />
					<div class="page8-depth-item">
						<div class="text-touch-notion">
							<img :class="firstIndex >= 2 || swiperIndex== 2 ? 'text-touch-notion2 fadeIn1SDelay1SEffect':'text-touch-notion2-1'"
								:src="testTouchSrc"></img>
						</div>
						<div
							:class="firstIndex >= 2 || swiperIndex== 2 ? 'text-touch-detail fadeIn1SDelay2SEffect':'text-touch-detail-1'">
							Do you want to start a project, join the crew or just say hi? We'd love to hear from you.
						</div>

						<!-- 三个按钮 service jobs contact -->
						<div
							:class="firstIndex >= 2 || swiperIndex== 2 ? 'three-botton-div fadeIn1SDelay3SEffect':'three-botton-div-1'">
							<img class="three-button" @click="onBtnService" :src="btnServiceSrc" />
							<img class="three-button" @click="onBtnJobs" :src="btnJobsSrc" />
							<img class="three-button" @click="onBtnContact" :src="btnContactSrc" />
						</div>
						<div :class="firstIndex >= 2 || swiperIndex== 2 ? 'select-div fadeIn1SDelay3SEffect':''">
							<!-- service -->
							<div v-if="isSelectService" class="input1">
								<div v-on:mouseover="onMouseButtonOver(0)" v-on:mouseout="onMouseButtonOut(0)"
									class="button-bottom" @click.prevent.stop="openServiceDetail(0)">
									<div class="text-bottom">360 Digital</div>
									<img class="input-arrow" :src="btnArrowSrc0" mode="widthFix" />
								</div>
								<div v-on:mouseover="onMouseButtonOver(1)" v-on:mouseout="onMouseButtonOut(1)"
									class="button-bottom" @click.prevent.stop="openServiceDetail(1)">
									<div>Experiential Marketing</div>
									<img class="input-arrow" :src="btnArrowSrc1" mode="widthFix" />
								</div>
								<div v-on:mouseover="onMouseButtonOver(2)" v-on:mouseout="onMouseButtonOut(2)"
									class="button-bottom" @click.prevent.stop="openServiceDetail(2)">
									<div>Database Management</div>
									<img class="input-arrow" :src="btnArrowSrc2" mode="widthFix" />
								</div>
							</div>
							<!-- jobs -->
							<div v-if="isSelectJobs" class="button2">
								<div v-for="(item, index) in jobTitleList" :key="index">
									<div v-on:mouseover="onMouseButtonOver(index)"
										v-on:mouseout="onMouseButtonOut(index)" class="button-bottom"
										@click.prevent.stop="openJobsDetail(index)">
										<div>{{item.title}}</div>
										<img class="input-arrow" :src="index == mIndex ? btnArrowSrc4: btnArrowSrc3"
											mode="widthFix" />
									</div>
								</div>
							</div>
							<!-- contact -->
							<div v-if="isSelectContact" class="button1">
								<input class="input-item input-full-name" v-model="fullNameStr" type="text"
									@input="bindInputFullNameChange()" placeholder="Full Name" />

								<input class="input-item input-email" v-model="emailStr" type="text"
									@input="bindInputEmailChange()" placeholder="Email" />

								<input class="input-item input-tell-us" v-model="tellUsStr" type="text"
									@input="bindInputTellUsChange()" placeholder="Feel free to leave a message." />
							</div>
							<img v-if="isSelectContact" class="submit" v-on:mouseover="onMousSubmitOver()"
								v-on:mouseout="onMousSubmitOut()" @click.prevent.stop="onBtnSubmit"
								:src="btnSubmitSrc" />
						</div>

						<div v-if="screenFlag != 3">
							<img :class="firstIndex >= 2 || swiperIndex== 2 ? 'page8-computer fadeIn1SDelay3SEffect':'page8-computer-1'"
								:src="page8RightSrc" mode="widthFix"></img>
						</div>
						<!-- bottom -->
						<div v-if="screenFlag != 3">
							<div
								:class="firstIndex >= 2 || swiperIndex== 2 ? 'gap-line fadeIn1SDelay4SEffect':'gap-line-1'">
							</div>
						</div>
						<div
							:class="firstIndex >= 2 || swiperIndex== 2 ? 'bottom-area fadeIn1SDelay4SEffect':'bottom-area-1'">
							<div class="bottom-area-item">
								<div class="bottom-area-item-first">
									<img :src="addressSrc" @click="onBtnAddressDetail" />
									<div @click.prevent.stop="onBtnAddressDetail">BASES</div>
								</div>
								<div class="arrow-div1">
									<div class="bottom-area-item-detail-office">
										<p v-on:mouseover="onMouseAddressDetailOver(1)"
											v-on:mouseout="onMouseAddressDetailOut()"
											@click.prevent.stop="onBtnAddressDetail"
											:style="{'transform': mouseOnAddressIdx == 1 ? 'scale(1.2)' : 'scale(1)'}">
											China</p>
										<p v-on:mouseover="onMouseAddressDetailOver(2)"
											v-on:mouseout="onMouseAddressDetailOut()"
											@click.prevent.stop="onBtnAddressDetail"
											:style="{'transform': mouseOnAddressIdx == 2 ? 'scale(1.2)' : 'scale(1)'}">
											South Korea</p>
										<p v-on:mouseover="onMouseAddressDetailOver(3)"
											v-on:mouseout="onMouseAddressDetailOut()"
											@click.prevent.stop="onBtnAddressDetail"
											:style="{'transform': mouseOnAddressIdx == 3 ? 'scale(1.2)' : 'scale(1)',}"
											:class="screenFlag == 3 ? 'singa-right': ''">
											Singapore</p>
									</div>
									<img v-if="screenFlag == 3" :src="arrowRightTopSrc" class="arrow-right-top1" />
								</div>
							</div>

							<div v-if="screenFlag == 3">
								<div :class="firstIndex >= 2 || swiperIndex== 2 ? 'gap-line1 fadeIn1SDelay4SEffect':''">
								</div>
							</div>
							<div class="bottom-area-item">
								<div class="bottom-area-item-first">
									<img :src="emailSrc" />
									<div>CONTACT</div>
								</div>
								<div class="arrow-div">
									<p class="bottom-area-item-detail">hello@notionbase.com</p>
									<img v-if="screenFlag == 3" :src="arrowRightTopSrc" class="arrow-right-top" />
								</div>
							</div>

							<div class="bottom-area-item">
								<div class="bottom-area-item-first">
									<img :src="socialSrc" />
									<div>FOLLOW US</div>
								</div>
								<div class="bottom-area-socials">
									<img @click.prevent.stop="onBtnWechat" v-on:mouseover="onMouseSocialOver(1)"
										v-on:mouseout="onMouseSocialOur()"
										:style="{'transform': mouseOnSocialIdx == 1 ? 'scale(1.2)' : 'scale(1)'}"
										:src="wechatSrc" />
									<img @click.prevent.stop="onBtnLinkedin" v-on:mouseover="onMouseSocialOver(2)"
										v-on:mouseout="onMouseSocialOur()"
										:style="{'transform': mouseOnSocialIdx == 2 ? 'scale(1.2)' : 'scale(1)'}"
										:src="linkedinSrc" />
									<img @click.prevent.stop="onBtnRedbook" v-on:mouseover="onMouseSocialOver(3)"
										v-on:mouseout="onMouseSocialOur()"
										:style="{'transform': mouseOnSocialIdx == 3 ? 'scale(1.2)' : 'scale(1)'}"
										:src="redbookSrc" />
								</div>
							</div>
							<div class="bottom-area-item" v-if="screenFlag != 3">
								<div class="bottom-area-item-first">
									<img :src="copyrightSrc" />
									<p>COPYRIGHT 2023</p>
								</div>
								<!-- <p class="text-company-backaup">ALL RIGHT RESERVED</p> -->
								<div class="text-company-backaup">
									<a href="https://beian.miit.gov.cn">
										<p>沪ICP备19015052号</p>
									</a>
								</div>
							</div>
							<div class="bottom-area-item1" v-if="screenFlag == 3">
								<div class="bottom-area-item-first1">
									<img :src="copyrightSrc" />
									<p>COPYRIGHT 2023</p>
								</div>
								<div class="text-company-backaup">
									<a href="https://beian.miit.gov.cn">
										<p>沪ICP备19015052号</p>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</swiper-slide>
			<!-- 分页器 -->
			<!-- <div class="swiper-pagination" slot="pagination"></div> -->
		</swiper>
		<transition name="fade">
			<div v-if="swiperIndex == 0 && swiperIndex1 == 0" class="case-scroll-view">
				<div v-if="screenFlag != 3" class="case-line-group" :style="{'width': width * 0.1208 + 'px'}" >
				</div>
			<swiper ref="caseSwiper" class="scroll-view" :style="{'width': width * (0.6-0.1208) + 'px'}" :options="caseSwiperOption">
				<swiper-slide class="hei">
					<div :class="caseIndex == 0 ? 'page6-case-item-selected' : ''"
						class="page6-case-item0 page6-case-item" v-on:mouseover="onMouseCaseOver(0)"
						v-on:mouseout="onMouseCaseOut()" @click="onBtnCaseSelect(0)">
						<div>{{case0Title}}</div>
						<img :class="firstIndex >= 0 || isBegin2 == false ? 'arrow-right fadeIn1SDelay1SEffect':'arrow-right-1'"
							:src="arrowRightSrc" />
					</div>
					</swiper-slide>
					<swiper-slide class="hei">
			
					<div :class="caseIndex == 1 ? 'page6-case-item-selected' : ''"
						class="page6-case-item1 page6-case-item" v-on:mouseover="onMouseCaseOver(1)"
						v-on:mouseout="onMouseCaseOut()" @click="onBtnCaseSelect(1)">
						<div>{{case1Title}}</div>
						<img :class="firstIndex >= 0 || isBegin2 == false ? 'arrow-right fadeIn1SDelay2SEffect':'arrow-right-1'"
							:src="arrowRightSrc" />
					</div>
					</swiper-slide>
					<swiper-slide class="hei">
			
					<div :class="caseIndex == 2 ? 'page6-case-item-selected' : ''"
						class="page6-case-item2 page6-case-item" v-on:mouseover="onMouseCaseOver(2)"
						v-on:mouseout="onMouseCaseOut()" @click="onBtnCaseSelect(2)">
						<div>{{case2Title}}</div>
						<img :class="firstIndex >= 0 || isBegin2 == false ? 'arrow-right fadeIn1SDelay3SEffect':'arrow-right-1'"
							:src="arrowRightSrc" />
					</div>
					</swiper-slide>
					<swiper-slide class="hei">
			
					<div :class="caseIndex == 3 ? 'page6-case-item-selected' : ''"
						class="page6-case-item3 page6-case-item" v-on:mouseover="onMouseCaseOver(3)"
						v-on:mouseout="onMouseCaseOut()" @click="onBtnCaseSelect(3)">
						<div>{{case3Title}}</div>
						<img :class="firstIndex >= 0 || isBegin2 == false ? 'arrow-right fadeIn1SDelay4SEffect':'arrow-right-1'"
							:src="arrowRightSrc" />
					</div>
					</swiper-slide>
					<swiper-slide class="hei">
			
					<div :class="caseIndex == 4 ? 'page6-case-item-selected' : ''"
						class="page6-case-item4 page6-case-item" v-on:mouseover="onMouseCaseOver(4)"
						v-on:mouseout="onMouseCaseOut()" @click="onBtnCaseSelect(4)">
						<div>{{case4Title}}</div>
						<img :class="firstIndex >= 0 || isBegin2 == false ? 'arrow-right fadeIn1SDelay5SEffect':'arrow-right-1'"
							:src="arrowRightSrc" />
					</div>
					</swiper-slide>
					<swiper-slide class="hei">
			
					<div :class="caseIndex == 5 ? 'page6-case-item-selected' : ''"
						class="page6-case-item4 page6-case-item" v-on:mouseover="onMouseCaseOver(5)"
						v-on:mouseout="onMouseCaseOut()" @click="onBtnCaseSelect(5)">
						<div>{{case5Title}}</div>
						<img :class="firstIndex >= 0 || isBegin2 == false ? 'arrow-right fadeIn1SDelay5SEffect':'arrow-right-1'"
							:src="arrowRightSrc" />
					</div>
					</swiper-slide>
					<swiper-slide class="hei">
			
					<div :class="caseIndex == 6 ? 'page6-case-item-selected' : ''"
						class="page6-case-item4 page6-case-item" v-on:mouseover="onMouseCaseOver(6)"
						v-on:mouseout="onMouseCaseOut()" @click="onBtnCaseSelect(6)">
						<div>{{case6Title}}</div>
						<img :class="firstIndex >= 0 || isBegin2 == false ? 'arrow-right fadeIn1SDelay6SEffect':'arrow-right-1'"
							:src="arrowRightSrc" />
					</div>
					</swiper-slide>
					<swiper-slide class="hei">
					<div :class="caseIndex == 7 ? 'page6-case-item-selected' : ''"
						class="page6-case-item4 page6-case-item" v-on:mouseover="onMouseCaseOver(7)"
						v-on:mouseout="onMouseCaseOut()" @click="onBtnCaseSelect(7)">
						<div>{{case7Title}}</div>
						<img :class="firstIndex >= 0 || isBegin2 == false ? 'arrow-right fadeIn1SDelay7SEffect':'arrow-right-1'"
							:src="arrowRightSrc" />
					</div>
					</swiper-slide>
					<swiper-slide class="hei">
					<div :class="caseIndex == 8 ? 'page6-case-item-selected' : ''"
						class="page6-case-item4 page6-case-item" v-on:mouseover="onMouseCaseOver(8)"
						v-on:mouseout="onMouseCaseOut()" @click="onBtnCaseSelect(8)">
						<div>{{case8Title}}</div>
						<img :class="firstIndex >= 0 || isBegin2 == false ? 'arrow-right fadeIn1SDelay8SEffect':'arrow-right-1'"
							:src="arrowRightSrc" />
					</div>
					</swiper-slide>
					<swiper-slide class="hei">
					<div :class="caseIndex == 9 ? 'page6-case-item-selected' : ''"
						class="page6-case-item4 page6-case-item" v-on:mouseover="onMouseCaseOver(9)"
						v-on:mouseout="onMouseCaseOut()" @click="onBtnCaseSelect(9)">
						<div>{{case9Title}}</div>
						<img :class="firstIndex >= 0 || isBegin2 == false ? 'arrow-right fadeIn1SDelay9SEffect':'arrow-right-1'"
							:src="arrowRightSrc" />
					</div>
					</swiper-slide>
					<swiper-slide class="hei">
					<div :class="caseIndex == 10 ? 'page6-case-item-selected' : ''"
						class="page6-case-item4 page6-case-item" v-on:mouseover="onMouseCaseOver(10)"
						v-on:mouseout="onMouseCaseOut()" @click="onBtnCaseSelect(10)">
						<div>{{case10Title}}</div>
						<img :class="firstIndex >= 0 || isBegin2 == false ? 'arrow-right fadeIn1SDelay10SEffect':'arrow-right-1'"
							:src="arrowRightSrc" />
					</div>
					</swiper-slide>
				
				</swiper>
			</div>
			</transition>
	
		<!-- 右上角菜单 -->
		<div v-if="!isFirstLoad" class="menu-content"
			:style="{'height': width * (screenFlag == 3 ? 0.06 : 0.023) + 'px'}">
			<img class="icon-menu fadeEffect" :src="iconLogoSrc" v-on:mouseover="onMouseMenuOver()"
				@click="onBtnLogo" />
			<div v-if="isShowMenu" class="list-menu fadeEffect">
				<div class="list-menu-content" v-for="(item, index) in menuList" :key="index">
					<div v-if="index == 0" class="list-menu-item-0">
						<div v-if="isShowVolIcon" @click="onBtnAudio" class="div-img0">
							<img class="list-menu-item-0-img" :src="volumeSrc" />
						</div>
					</div>
					<div v-else class="list-menu-item" v-on:mouseover="onMouseOver(index)" v-on:mouseout="onMouseOut()"
						@click="onBtnSelect(index)">
						<img v-if="currentMenuIndex != index" class="list-menu-item-text" :src="item.text" />
						<img v-if="currentMenuIndex == index" class="list-menu-item-gif" :src="item.gif" />
					</div>
				</div>
			</div>
		</div>

		<div class="list-menu-up" v-on:mouseout="onMouseMenuOut()"></div>
		<div v-if="screenFlag != 3" class="list-menu-down" v-on:mouseout="onMouseMenuOut()"
			:style="{'top': height * 0.031 +  width * 0.023 + 'px'}">
		</div>
		<div v-if="screenFlag != 3" class="list-menu-left" v-on:mouseout="onMouseMenuOut()"
			:style="{'height': width * 0.023 + 40 + 'px'}">
		</div>
		<div class="list-menu-right" v-on:mouseout="onMouseMenuOut()" :style="{'height': width * 0.023 + 40 + 'px'}">
		</div>

		<div v-if="showCaseDetail" class="case-detail fadeIn1SEffect">
			<div class="service-zz" @click="closeCode($event)"></div>
			<div class="case-center">

				<!-- <div v-if="screenFlag != 3" class="case-detail-swiper-content">
				<swiper ref="caseDetailSwiper" class="case-detail-swiper" :options="swiperOption">
					<swiper-slide class="case-detail-swiper-item" v-for="(item, index) in casesArr[caseIndex].detail"
						:key="index"
						:style="{'height': width * 0.43 * 180 / 320 + 'px', 'margin-top': (height - width * 0.43 * 180 / 320) / 2 + 'px'}">
						<div class="case-detail-swiper-item-wrap">
							<img :src="item.gif" />
						</div>
					</swiper-slide> -->
				<!-- 分页器 -->
				<!-- 	<div class="swiper-pagination caseDots" slot="pagination"
						:style="{'height': (height - width * 0.43 * 180 / 320) / 2 - height * 0.046 + 'px'}"></div>
				</swiper>
			</div>
 -->
				<!-- <div v-if="screenFlag != 3" class="case-detail-item-text"
 	:style="{'height': width * 0.43 * 180 / 320 + 'px', 'margin-top': (height - width * 0.43 * 180 / 320) / 2 + 'px'}">
 	<div class="case-detail-item-title">{{casesArr[caseIndex].detail[caseDetailIndex].title}}</div>
 	<div class="case-detail-item-desc">{{casesArr[caseIndex].detail[caseDetailIndex].desc}}</div>
 </div> -->
				<div v-if="screenFlag != 3" class="case-detail-swiper-content">
					<div class="case-detail-content-item1" v-for="(item, index) in casesArr[caseIndex].detail"
						:key="index">
						<img :src="item.gif" />
						<div class="case-detail-item-title">{{item.title}}</div>
						<div class="case-detail-item-desc">{{item.desc}}</div>
					</div>
					<div v-if="casesArr[caseIndex].detail2" class="case-detail-content-item1" v-for="(item, index) in casesArr[caseIndex].detail2"
						:key="'info'+ index">
						<img :src="item.gif" />
						<div class="case-detail-item-desc2">{{item.text}}</div>
					</div>
				</div>

				<div v-if="screenFlag == 3" class="case-detail-content">
					<div class="case-detail-content-item" v-for="(item, index) in casesArr[caseIndex].detail"
						:key="index">
						<img :src="item.gif" />
						<div class="case-detail-item-title">{{item.title}}</div>
						<div class="case-detail-item-desc">{{item.desc}}</div>
					</div>
					<div v-if="casesArr[caseIndex].detail2" class="case-detail-content-item" v-for="(item, index) in casesArr[caseIndex].detail2"
						:key="'info'+ index">
						<img :src="item.gif" />
						<div class="case-detail-item-desc2">{{item.text}}</div>
					</div>
				</div>
				<img class="case-detail-item-close" :src="btnBackSrc" @click="onBtnDetailClose"></img>
			</div>
		</div>

		<!-- service -->
		<div v-if="isService" class="service-detail fadeIn1SEffect">
			<div class="service-zz" @click="closeCode($event)"></div>
			<div class="service-center">
				<p class="service-desc">{{serviceText[id]}}</p>
				<img class="social-detail-item-close" :src="btnBackSrc" @click="onBtnDetailClose"></img>
			</div>
		</div>

		<!--jobs -->
		<div v-if="isJobs" class="jobs-detail fadeIn1SEffect">
			<div class="service-zz" @click="closeCode($event)"></div>
			<div class="jobs-center">
				<div class="jobs-center1">
					<div v-for="(item, index) in contentList" :key="index">
						<p style="opacity: 0;">1</p>
						<p style="opacity: 0;">1</p>
						<p class="jobs-desc2">{{item.name}} :</p>
						<p style="opacity: 0;">1</p>
						<p class="jobs-desc3" style="white-space: pre-wrap">{{item.content}}</p>
					</div>

					<p style="opacity: 0;">1</p>
					<div class="apply-div">
						<img class="btn-apply" :src="btnApplySrc" v-on:mouseover="onMouseApplyOver()"
							v-on:mouseout="onMouseApplyOut()" @click.prevent.stop="onBtnApply">
						</img>
					</div>
				</div>
				<img class="jobs-detail-item-close" :src="btnBackSrc" @click="onBtnDetailClose"></img>
			</div>
		</div>

		<!-- 发送邮件弹框 -->
		<div v-if="isShowMailBox" class="mail-detail fadeIn1SEffect">
			<div class="service-zz" @click="closeCode($event)"></div>
			<div class="mail-center">
				<div class="mail-content">
					<!-- <p v-if="screenFlag != 3" class="service-desc">If you are interested, welcome to send and we will get in touch with you as
					soon as possible.
				</p> -->
					<input class="input-item2 input-full-name" v-model="fullNameStr1" type="text"
						@input="bindInputFullNameChange1()" placeholder="Full Name" />
					<input class="input-item2 input-email" v-model="emailStr1" type="text"
						@input="bindInputEmailChange1()" placeholder="Email" />
					<div class="input-div">
						<input class="input-item1 input-tell-us" v-model="tellUsStr1" type="text"
							@input="bindInputTellUsChange1()"
							placeholder="Welcome to submit your resume to our mailbox" />
						<!-- 文件上传 -->
						<img class="mail-icon-add" :src="iconAddSrc" mode="widthFix" @click=""></img>
						<input type="file" accept=".pdf,.doc,.docx" class="input-file" id="inputFile"
							@change="fileChange()" />
					</div>
					<div class="submit-div">
						<img class="btn-submit" v-on:mouseover="onMousSubmitOver()" v-on:mouseout="onMousSubmitOut()"
							@click="onBtnSubmit1" :src="btnSubmitSrc" />
					</div>
				</div>
				<img class="social-detail-item-close" :src="btnBackSrc" @click="onBtnDetailClose"></img>
			</div>
		</div>

		<div v-if="isShowWechat || isShowLinkedin || isShowRedbook" class="social-detail fadeIn1SEffect">
			<div class="service-zz" @click="closeCode($event)"></div>
			<div class="social-center">
				<img class="social-detail-item" :src="qrcodeSrc"></img>
				<img class="social-detail-item-close" :src="btnBackSrc" @click="onBtnDetailClose"></img>
			</div>
		</div>

		<div v-if="isSubmit" class="submit-result fadeIn1SEffect">
			<P style="opacity: 0;" v-if="screenFlag == 3">1</P>
			<P style="opacity: 0;" v-if="screenFlag == 3">1</P>
			<P style="opacity: 0;" v-if="screenFlag == 3">1</P>
			<p class="submit-result-thanks">thank you</p>
			<div v-if="screenFlag == 3" class="submit-desc-text">
				<p class="submit-result-desc">We have received your application.</p>
				<p class="submit-result-desc">We’ll get back to you as soon as possible!</p>
			</div>
			<p v-else class="submit-result-desc">We have received your application. We’ll get back to you as soon as
				possible!
			</p>
			<img class="btn-confirm fadeInEffect" @click="onBtnConfirm" :src="confirmSrc"
				v-on:mouseover="onMouseConfirmOver()" v-on:mouseout="onMouseConfirmOut()" />
			<P style="opacity: 0;">1</P>
			<P style="opacity: 0;" v-if="screenFlag == 3">1</P>
			<P style="opacity: 0;" v-if="screenFlag == 3">1</P>

			<!-- <div class="btn-confirm" @click="onBtnConfirm">CONFIRM</div> -->
		</div>
		<div v-if="isShowAddressDetail" class="address-detail fadeIn1SEffect">
			<div class="service-zz" @click="closeCode($event)"></div>
			<div class="address-center">
				<div class="address-item">
					<div class="address-item-title">
						<img class="icon-flag" :src="chinaSrc" mode="widthFix"></img>
						<p>China</p>
					</div>
					<div class="address-item-detail">
						<div class="address-item-detail-item">
							<img :src="iconAddressSrc" />
							<p>No. 285, Jianguo(West) Road, KeTou Building, Unit 1502, Xuhui District,shanghai</p>
						</div>
						<div class="address-item-detail-item">
							<img :src="iconPhoneSrc" />
							<p class="address-item-detail-item-phone">+021 50676857</p>
						</div>
					</div>
				</div>
				<div class="address-item">
					<div class="address-item-title">
						<img class="icon-flag" :src="koreaSrc"></img>
						<p>South Korea</p>
					</div>
					<div class="address-item-detail">
						<div class="address-item-detail-item">
							<img :src="iconAddressSrc" />
							<p>Tower 3102, 13 Heungdeok 1 ro, Yongin-si, Gyeonggi-do, Korea 16954</p>
						</div>
						<div class="address-item-detail-item">
							<img :src="iconPhoneSrc" />
							<p class="address-item-detail-item-phone">+82-70 8810 9049</p>
						</div>
					</div>
				</div>
				<!-- <div class="address-item-gap-line"></div> -->
				<div class="address-item">
					<div class="address-item-title">
						<img class="icon-flag" :src="singaporeSrc"></img>
						<p>Singapore</p>
					</div>
					<div class="address-item-detail">
						<div class="address-item-detail-item">
							<img :src="iconAddressSrc" />
							<p>81 Ubi Ave 4, #05-24, Singapore 408830</p>
						</div>
						<div class="address-item-detail-item">
							<img :src="iconPhoneSrc" />
							<p class="address-item-detail-item-phone">+65 67492595</p>
						</div>
					</div>
				</div>
				<!-- <div class="address-item-gap-line"></div> -->
				<img class="social-detail-item-close" :src="btnBackSrc" @click="onBtnDetailClose"></img>
			</div>
		</div>

		<div v-if="isBegin" class="loading-page">
			<div class="page1">
				<!-- <img class="page1-bg" src="~@/assets/images/index/open-loading.gif"
					:style="{'opacity': isVideoPlay ? 0 : 1}" /> -->
				<video :muted="isMute" v-if="screenFlag == 3" id="video1" class="video" webkit-playsinline="true"
					playsinline="true" x-webkit-airplay="allow" x5-video-player-type="h5"
					x5-video-player-fullscreen="true" :src="chapterPhone1Src" :style="{'opacity': isVideoPlay ? 1 : 0}"
					@ended="videoPlayEnded">
				</video>
				<video v-else :muted="isMute" id="video1" class="video" webkit-playsinline="true" playsinline="true"
					x-webkit-airplay="allow" x5-video-player-type="h5" x5-video-player-fullscreen="true"
					:src="chapter1Src" :style="{'opacity': isVideoPlay ? 1 : 0}" @ended="videoPlayEnded">
				</video>

				<div v-if="!isVideoPlay" class="page1-slogan-1">{{sloganStr1}}</div>
				<div v-if="!isVideoPlay" class="page1-slogan-2">{{sloganStr2}}</div>

				<img v-if="isShowEnter && !isVideoPlay" class="page1-btn-enter fadeInEffect"
					v-on:mouseover="onMouseEnterOver(0)" v-on:mouseout="onMouseEnterOut(0)" @click="onBtnEnter"
					:src="enterSrc" />
				<!-- enter video2 -->
				<img v-if="isShowWelcome && isVideoPlay" class="btn-chapter2-begin fadeInEffect"
					v-on:mouseover="onMouseEnterOver(1)" v-on:mouseout="onMouseEnterOut(1)" @click="onBtnEnter2"
					:src="btnChapter2BeginSrc" />
				<!-- skip to end按钮 -->
				<div v-if="!isFirstLoad && isVideoPlay" class="page7-btn-skip-wrap"
					:style="{'height': width * (screenFlag == 3 ? 0.15 : 0.078) * 80 / 300 + 'px'}">
					<div :class="skipAni == true ? 'skipBgEffect1' : ''" class="page7-btn-skip"
						:style="{'opacity': btnSkipOpacity}" v-on:mouseover="onMouseSkipOver()"
						v-on:mouseout="onMouseSkipOut()" @click="onBtnSkipToEnd1">
						<img :src="btnSkipSrc" />
					</div>
				</div>
				<!-- 透明按钮skip to end -->
				<div v-if="isFirstLoad && isVideoPlay" class="btn-end" @click="onBtnEnter2"></div>
			</div>
		</div>

		<div v-show="isBegin2" class="loading-page">
			<div class="page1">
				<video v-if="screenFlag == 3" id="video2" :muted="isMute" class="video1" webkit-playsinline="true"
					playsinline="true" x-webkit-airplay="allow" x5-video-player-type="h5"
					x5-video-player-fullscreen="true" :src="chapterPhone2Src" :style="{'opacity': isVideoPlay1 ? 1 : 0}"
					@ended="videoPlayEnded">
				</video>
				<video v-else id="video2" :muted="isMute" class="video1" webkit-playsinline="true" playsinline="true"
					x-webkit-airplay="allow" x5-video-player-type="h5" x5-video-player-fullscreen="true"
					:src="chapter2Src" :style="{'opacity': isVideoPlay1 ? 1 : 0}" @ended="videoPlayEnded">
				</video>

				<!-- <div v-if="!isVideoPlay" class="page-chapter2-begin">{{notionWelStr}}</div> -->

				<!-- <img v-if="isShowWelcome && !isVideoPlay" class="btn-chapter2-begin fadeInEffect"
					v-on:mouseover="onMouseEnterOver()" v-on:mouseout="onMouseEnterOut()" @click="onBtnEnter"
					:src="btnChapter2BeginSrc" /> -->
			</div>
		</div>

		<audio :src="clickSrc" hidden ref="clickAudio"></audio>
	</div>
</template>

<script>
	import {
		url_bottom_address,
		url_bottom_copyright,
		url_bottom_email,
		url_bottom_icon_address,
		url_bottom_icon_phone,
		url_bottom_redbook,
		url_bottom_social,
		url_bottom_wechat,

		url_case_default_phone,
		url_case_default,
		url_case_dickies_phone,
		url_case_dickies,
		url_case_dior_phone,
		url_case_dior,
		url_case_GM_phone,
		url_case_GM,
		url_case_Martell_phone,
		url_case_Martell,
		url_case_Tiffany_phone,
		url_case_Tiffany,

		url_case_vw,
		url_case_vw_phone,
		url_case_chaumet,
		url_case_chaumet_phone,
		url_case_delvaux,
		url_case_delvaux_phone,
		url_case_dg,
		url_case_dg_phone,
		url_case_valorant,
		url_case_valorant_phone,
		url_case_hw,
		url_case_hw_phone,

		url_common_arrow_70,
		url_common_arrow_100,
		url_common_arrow_right_top,
		url_common_arrow_right,
		url_common_btn_apply_def,

		url_common_btn_apply,
		url_common_btn_back,
		url_common_btn_close,
		url_common_btn_confirm_def,
		url_common_btn_confirm,

		url_common_btn_contact_sel,
		url_common_btn_contact,
		url_common_btn_enter_base_def,
		url_common_btn_enter_base_sel,
		url_common_btn_enter_default,

		url_common_btn_enter_select,
		url_common_btn_jobs_sel,
		url_common_btn_jobs,
		url_common_btn_menu,
		url_common_btn_replay_select,

		url_common_btn_replay,
		url_common_btn_service_sel,
		url_common_btn_service,
		url_common_btn_skip,
		url_common_btn_start_exploring_def,

		url_common_btn_start_exploring_sel,
		url_common_btn_submit_def,
		url_common_btn_submit_sel,
		url_common_china,
		url_common_icon_add,

		url_common_icon_logo,
		url_common_korea,
		url_common_singapore,
		url_common_next_chpt,
		url_common_next_chpt_sel,

		url_common_arrow_next,

		url_index_astronaut,
		url_index_bg_page_6_small,
		url_index_bg_page_6,
		url_index_bg_page_7,
		url_index_bg_page_8,
		url_index_computer,

		url_index_phone,
		url_index_spaceman,
		url_index_test_footprints,
		url_index_test_touch,
		url_index_top_black,

		url_logo_bottom,
		url_logo_thanks,

		url_menu_client,
		url_menu_home,
		url_menu_contact,
		url_menu_project,
		url_menu_client_gif,
		url_menu_home_gif,
		url_menu_contact_gif,
		url_menu_project_gif,
		url_menu_icon_mute,
		url_menu_icon_volume,

		url_social_redbook,
		url_social_linkedin,
		url_social_wechat,

	} from '@/gdata/g_image.js'
	import {
		url_audio_click,
		url_video_chapter_1,
		url_video_chapter_2,
		url_video_chapter_phone_1,
		url_video_chapter_phone_2,
	} from '@/gdata/g_video.js'

	import "swiper/css/swiper.css"
	import {
		Swiper,
		SwiperSlide
	} from "vue-awesome-swiper"

	const TWEEN = require('@tweenjs/tween.js')

	import Parallax from 'parallax-js'

	import {
		g_casesArr,
	} from './data.js'

	export default {
		components: {
			Swiper,
			SwiperSlide,
		},
		data() {
			let _this = this
			return {
				width: window.innerWidth,
				height: window.innerHeight,
				pixelRatio: window.pixelRatio,
				timer3: '',
				isEnd: true, // 辅助控制videoPlayEnd

				screenFlag: 0, // 0:屏幕宽度大于1450，1：:屏幕宽度大于980，2：:屏幕宽度大于500，3：:屏幕宽度小于500，

				isBegin: true,
				isBegin2: false,

				isShowMenu: false,
				isFirstLoad: true, // 是否显示菜单
				menuList: [{
						text: url_menu_icon_mute,
						gif: url_menu_icon_mute,
					},
					{
						text: url_menu_home,
						gif: url_menu_home_gif,
					},
					{
						text: url_menu_project,
						gif: url_menu_project_gif,
					},
					{
						text: url_menu_client,
						gif: url_menu_client_gif,
					},
					{
						text: url_menu_contact,
						gif: url_menu_contact_gif,
					},
				],
				currentMenuIndex: -1,

				isMute: false,
				volumeSrc: url_menu_icon_volume,

				swiperIndex: -1,
				firstIndex: -1,
				swiperIndex1: 0,
				caseSwiperOption: {
					direction: 'vertical',
					slidesPerView: 5,
					spaceBetween: 20,
					mousewheel: true,
					pagination: {
						el: ".swiper-pagination", //分页器作用对象
						clickable: true, //分页器小圆点是否可点击
					},
					},
				pageSwiperOption: {
					direction: 'vertical',
					slidesPerView: 1,
					spaceBetween: 30,
					mousewheel: true,
					pagination: {
						el: ".swiper-pagination", //分页器作用对象
						clickable: true, //分页器小圆点是否可点击
					},
					on: {
						slideChange() {
							console.log('page slideChange: ', this.activeIndex)
							_this.swiperIndex = this.activeIndex
							// _this.pageReset()
							if (this.activeIndex > _this.firstIndex) {
								// 滑到当前页
								_this.firstIndex = this.activeIndex;
								// localStorage.setItem('firstIndex', _this.firstIndex)
							}

							let timeout = 2000
							if (_this.screenFlag == 3) {
								timeout = 1000
							}
							if (this.activeIndex == 0) {
								_this.caseTimer0 = setTimeout(() => {
									// _this.page6CaseAppear(0)
									clearTimeout(_this.caseTimer0)
								}, timeout)
								_this.caseTimer1 = setTimeout(() => {
									// _this.page6CaseAppear(1)
									clearTimeout(_this.caseTimer1)
								}, timeout + 1000)
								_this.caseTimer2 = setTimeout(() => {
									// _this.page6CaseAppear(2)
									clearTimeout(_this.caseTimer2)
								}, timeout + 2000)
								_this.caseTimer3 = setTimeout(() => {
									// _this.page6CaseAppear(3)
									clearTimeout(_this.caseTimer3)
								}, timeout + 3000)
								_this.caseTimer4 = setTimeout(() => {
									// _this.page6CaseAppear(4)
									clearTimeout(_this.caseTimer4)
								}, timeout + 4000)
							} else if (this.activeIndex == 1) {
								_this.player.pause()
								_this.thanksOffset = 0
								_this.thanksScroll()
							} else if (this.activeIndex == 2) {
								_this.player.pause()
								_this.$nextTick(() => {
									_this.initParallax()
								})

							}
						},
					},
				},
				lineList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],

				sloganStr1: '',
				sloganStr2: '',
				sloganTimer1: null,
				sloganTimer2: null,

				notionWelStr: '',
				notionWelTimer: null,
				isShowWelcome: false,

				isVideoPlay: false,
				isVideoPlay1: false,
				isShowEnter: false,
				player: null,
				enterSrc: url_common_btn_start_exploring_def,
				btnChapter2BeginSrc: url_common_btn_enter_base_def,

				selectCaseSrc: url_case_default,
				selectCasePhoneSrc: url_case_default_phone,
				arrowNextSrc: url_common_arrow_next,
				caseIndex: -1,
				casesArr: g_casesArr,
				caseTimer0: null,
				caseTimer1: null,
				caseTimer2: null,
				caseTimer3: null,
				caseTimer4: null,

				case0Title: "",
				case1Title: "",
				case2Title: "",
				case3Title: "",
				case4Title: "",
				case5Title: "",
				case6Title: "",
				case7Title: "",
				case8Title: "",
				case9Title: "",
				case10Title: "",


				thanksOffset: 0,
				thansAnimFrame: -1,
				btnReplaySrc: url_common_btn_replay,
				btnReplayOpacity: 1,
				btnSkipOpacity: 1,
				isClickRefresh: false,
				isShowNpcAnim: false,

				astrouautImg: null,
				astrouautMoveTimer: null,
				astrouautMoveX: 0,
				astrouautMoveY: 0,
				astrouautAnimFrame: -1,
				astrouautAnimCanvas: null,
				astrouautAnimCtx: null,
				astronautCurrent: {
					x: 20,
					y: 20
				},
				astronautTarget: {
					x: window.innerWidth * 0.87,
					y: window.innerHeight * 0.9 - window.innerWidth * 0.13 * 1836 / 1092
				},

				fullNameStr: "",
				companyStr: "",
				emailStr: "",
				fullNameStr1: "",
				emailStr1: "",
				tellUsStr1: "",
				webSiteStr: "",
				tellUsStr: "",

				isSelectService: true,
				isSelectJobs: false,
				isSelectContact: false,
				isService: false,
				isJobs: false,
				isContact: false,
				skipAni: false,
				btnServiceSrc: url_common_btn_service_sel,
				btnJobsSrc: url_common_btn_jobs,
				btnContactSrc: url_common_btn_contact,
				btnArrowSrc0: url_common_arrow_70,
				btnArrowSrc1: url_common_arrow_70,
				btnArrowSrc2: url_common_arrow_70,
				btnArrowSrc3: url_common_arrow_70,
				btnArrowSrc4: url_common_arrow_100,
				btnSubmitSrc: url_common_btn_submit_def,
				btnApplySrc: url_common_btn_apply_def,
				confirmSrc: url_common_btn_confirm_def,
				arrowRightSrc: url_common_arrow_right,
				page8RightSrc: url_index_computer,
				isShowMailBox: false,
				isCanSubmit: false,
				isCanSubmit1: false,
				isSubmit: false,
				isAfterSubmitAnim: false,
				isMouseOnSubmit: false,
				pauseTimer: null,
				socialArr: [
					url_social_wechat,
					url_social_linkedin,
					url_social_redbook,
				],
				isShowWechat: false,
				isShowLinkedin: false,
				isShowRedbook: false,
				mouseOnSocialIdx: -1,
				qrcodeSrc: url_social_wechat,

				isShowAddressDetail: false,
				mouseOnAddressIdx: -1,

				showCaseDetail: false,
				caseDetailIndex: 0,
				swiperOption: {
					effect: 'cards',
					// grabCursor: true,
					// cubeEffect: {
					// 	shadow: true,
					// 	slideShadows: true,
					// 	shadowOffset: 20,
					// 	shadowScale: 0.94
					// },
					pagination: {
						el: ".swiper-pagination", //分页器作用对象
						clickable: true, //分页器小圆点是否可点击
					},
					on: {
						slideChange() {
							console.log('slideChange: ', this.activeIndex)
							_this.caseDetailIndex = this.activeIndex
						},
					},
				},

				preloadImgs: [
					url_case_default,
					url_case_dickies,
					url_case_dior,
					url_case_GM,
					url_case_Martell,
					url_case_Tiffany,


					// require('@/assets/images/index/bg-page-2.gif'),
					// require('@/assets/images/index/bg-page-3.gif'),
					// require('@/assets/images/index/bg-page-4.gif'),
					// require('@/assets/images/index/bg-page-5.gif'),
					// require('@/assets/images/index/bg-page-6.gif'),
					// require('@/assets/images/index/bg-page-6.gif'),
				],
				mIndex: -1,
				jobTitleList: '',
				jobContentList: '',
				contentList: '',
				selectTitle: '',
				selectTitleId: '',
				userFile: '',
				isShowVolIcon: true,
				serviceText: [
					"Our innovative technology enables brands to connect with audiences in a more meaningful way than ever before. We build an ecosystem that monetizes the content to its full potential.",
					"We focus on turning complex ideas into clear results, transforming customers' ideas into multi-dimensional experiences. Tailor-made unique interactive experience solutions for brands based on customized marketing needs.",
					"Data resources are an important part of brand strategy construction. Notionbase's powerful data analysis skills and database management escort the effectiveness of brand digital marketing."
				],
				id: '',
				addressSrc: url_bottom_address,
				copyrightSrc: url_bottom_copyright,
				emailSrc: url_bottom_email,
				socialSrc: url_bottom_social,
				iconAddressSrc: url_bottom_icon_address,
				iconPhoneSrc: url_bottom_icon_phone,
				arrowRightTopSrc: url_common_arrow_right_top,
				btnBackSrc: url_common_btn_back,
				btnSkipSrc: url_common_btn_skip,
				chinaSrc: url_common_china,
				iconAddSrc: url_common_icon_add,
				iconLogoSrc: url_common_icon_logo,
				koreaSrc: url_common_korea,
				singaporeSrc: url_common_singapore,
				bgPage6SmallSrc: url_index_bg_page_6_small,
				bgPage6Src: url_index_bg_page_6,
				bgPage7Src: url_index_bg_page_7,
				bgPage8Src: url_index_bg_page_8,
				testFootprintsSrc: url_index_test_footprints,
				testTouchSrc: url_index_test_touch,
				topBlackSrc: url_index_top_black,
				bottomSrc: url_logo_bottom,
				thanksSrc: url_logo_thanks,
				wechatSrc: url_social_wechat,
				redbookSrc: url_social_redbook,
				linkedinSrc: url_social_linkedin,
				chapterPhone1Src: url_video_chapter_phone_1,
				chapter1Src: url_video_chapter_1,
				chapterPhone2Src: url_video_chapter_phone_2,
				chapter2Src: url_video_chapter_2,
				clickSrc: url_audio_click,
				btnNextChptSrc: url_common_next_chpt,
			}
		},
		mounted() {
			console.log("home mounted")

			// 0:屏幕宽度大于1450，
			// 1：:屏幕宽度大于980，
			// 2：:屏幕宽度大于500，
			// 3：:屏幕宽度小于500，
			if (this.width >= 1450) {
				this.screenFlag = 0
			} else if (this.width >= 980) {
				this.screenFlag = 1
			} else if (this.width >= 500) {
				this.screenFlag = 2
			} else {
				this.screenFlag = 3
			}

			console.log("screen flag: ", this.screenFlag)

			window.addEventListener('resize', () => {
				console.log('window resize')

				this.width = window.innerWidth
				this.height = window.innerHeight

				if (this.astrouautAnimCanvas != null) {
					this.astrouautAnimCanvas.width = this.width
					this.astrouautAnimCanvas.hieght = this.height
				}

				// 0:屏幕宽度大于1450，
				// 1：:屏幕宽度大于980，
				// 2：:屏幕宽度大于500，
				// 3：:屏幕宽度小于500，
				if (this.width >= 1450) {
					this.screenFlag = 0
				} else if (this.width >= 980) {
					this.screenFlag = 1
				} else if (this.width >= 500) {
					this.screenFlag = 2
				} else {
					this.screenFlag = 3
				}

				console.log("screen flag: ", this.screenFlag)
			})
			window.addEventListener('touchmove', (event) => {
				console.log('touch move')
			})
			window.addEventListener('mousemove', (event) => {
				// console.log('mouse move')
			})

			// 开始
			this.chapter1SloganAppear1("We Create Experience,")
			// 结束

			// 直接到第二章节案例 开始
			// this.isBegin = false
			// this.isBegin2 = false
			// this.swiperIndex = 0
			// this.isVideoPlay = false
			// this.isShowEnter = false
			// this.isShowWelcome = false

			// let timeout = 2000
			// if (this.screenFlag == 3) {
			// 	timeout = 1000
			// }
			// this.caseTimer0 = setTimeout(() => {
			// 	this.page6CaseAppear(0)
			// 	clearTimeout(this.caseTimer0)
			// }, timeout)
			// this.caseTimer1 = setTimeout(() => {
			// 	this.page6CaseAppear(1)
			// 	clearTimeout(this.caseTimer1)
			// }, timeout + 1000)
			// this.caseTimer2 = setTimeout(() => {
			// 	this.page6CaseAppear(2)
			// 	clearTimeout(this.caseTimer2)
			// }, timeout + 2000)
			// this.caseTimer3 = setTimeout(() => {
			// 	this.page6CaseAppear(3)
			// 	clearTimeout(this.caseTimer3)
			// }, timeout + 3000)
			// this.caseTimer4 = setTimeout(() => {
			// 	this.page6CaseAppear(4)
			// 	clearTimeout(this.caseTimer4)
			// }, timeout + 4000)
			// 直接到第二章节案例 结束

			this.getJobList() // 获取职位列表
		},
		beforeDestroy() {},
		methods: {
			caseSwiperChange(idx) {
				console.log('000')
				// this.$refs.caseSwiper.$swiper.slideTo(idx, 1000, false)
			},
			closeCode(e) {
				if (this.isService) {
					this.isService = false
				}
				if (this.isJobs) {
					this.isJobs = false
				}
				if (this.isShowMailBox) {
					this.isShowMailBox = false
				}
				if (this.isShowWechat) {
					this.isShowWechat = false
				}
				if (this.isShowLinkedin) {
					this.isShowLinkedin = false
				}
				if (this.isShowRedbook) {
					this.isShowRedbook = false
				}
				if (this.isShowAddressDetail) {
					this.isShowAddressDetail = false
				}
				if (this.showCaseDetail) {
					this.showCaseDetail = false
				}
			},
			// nextPage(id) {
			// 	if(id == 0) {
			// 		this.$refs.pageSwiper.$swiper.slideTo(1, 1000, false)
			// 	} else {
			// 		this.$refs.pageSwiper.$swiper.slideTo(2, 1000, false)
			// 	}				
			// },
			// input标签上的change事件
			fileChange() {
				this.userFile = document.getElementById("inputFile").files
				console.log(this.userFile)
			},
			// 获取职位列表
			getJobList() {
				console.log('getJobList')
				this.$axios.post("https://activity.notionbase.com/api/project/website/job-list", {}).then(res => {
					console.log('job res', res)
					if (res.data.status == 0) {
						this.jobTitleList = res.data.data.title_list
						this.jobContentList = res.data.data.content_list
					}
				})
			},
			preloadImgsFunc() {
				var astrouaut = new Image()
				astrouaut.src = url_index_astronaut
				astrouaut.onload = () => {
					this.astrouautImg = astrouaut
				}

				let count = 0
				for (let img of this.preloadImgs) {
					let image = new Image()
					image.onload = () => {
						count++
						console.log('image preload : ', count)
					}

					image.src = img
				}
			},
			pageReset() {
				clearTimeout(this.caseTimer0)
				clearTimeout(this.caseTimer1)
				clearTimeout(this.caseTimer2)
				clearTimeout(this.caseTimer3)
				clearTimeout(this.caseTimer4)
				clearTimeout(this.caseTimer5)
				clearTimeout(this.caseTimer6)
				clearTimeout(this.caseTimer7)
				clearTimeout(this.caseTimer8)
				clearTimeout(this.caseTimer9)
				clearTimeout(this.caseTimer10)

				this.case0Title = ""
				this.case1Title = ""
				this.case2Title = ""
				this.case3Title = ""
				this.case4Title = ""
				this.case5Title = ""
				this.case6Title = ""
				this.case7Title = ""
				this.case8Title = ""
				this.case9Title = ""
				this.case10Title = ""

				this.caseIndex = -1
				this.selectCaseSrc = url_case_default
				this.selectCasePhoneSrc = url_case_default_phone
				this.caseDetailIndex = 0

				this.thanksOffset = 0
				this.thanksScrollEnd()
			},
			chapter1SloganAppear1(content) {
				console.log("chapter1SloganAppear1")

				let that = this

				this.sloganStr1 = ""

				clearTimeout(this.sloganTimer1)

				let speed = 80
				let count = 1

				function changeContent() {
					that.sloganStr1 = content.substring(0, count)

					count++

					if (count < content.length + 1) {
						speed -= 1
						if (speed < 5) speed = 5

						that.sloganTimer1 = setTimeout(changeContent, speed)
					} else {
						that.chapter1SloganAppear2("Unexampled.")
					}
				}
				changeContent()
			},
			chapter1SloganAppear2(content) {
				console.log("chapter1SloganAppear2")

				let that = this

				this.sloganStr2 = ""

				clearTimeout(this.sloganTimer2)

				let speed = 80
				let count = 1

				function changeContent() {
					that.sloganStr2 = content.substring(0, count)

					count++

					if (count < content.length + 1) {
						speed -= 1
						if (speed < 5) speed = 5

						that.sloganTimer2 = setTimeout(changeContent, speed)
					} else {
						console.log('show enter btn')
						that.isShowEnter = true
					}
				}
				changeContent()
			},
			chapter2NotionAppear(content) {
				console.log("chapter2NotionAppear")

				let that = this
				this.notionWelStr = ""

				clearTimeout(this.notionWelTimer)

				let speed = 80
				let count = 1

				function changeContent() {
					that.notionWelStr = content.substring(0, count)

					count++

					if (count < content.length + 1) {
						speed -= 1
						if (speed < 5) speed = 5

						that.notionWelTimer = setTimeout(changeContent, speed)
					} else {
						that.isShowWelcome = true
					}
				}
				changeContent()
			},
			onMouseConfirmOver() {
				this.confirmSrc = url_common_btn_confirm
			},
			onMouseConfirmOut() {
				this.confirmSrc = url_common_btn_confirm_def
			},
			onMouseEnterOver(idx) {
				if (idx == 0) {
					this.enterSrc = url_common_btn_start_exploring_sel
				} else if (idx == 1) {
					this.btnChapter2BeginSrc = url_common_btn_enter_base_sel
				}
			},
			onMouseEnterOut(idx) {
				if (idx == 0) {
					this.enterSrc = url_common_btn_start_exploring_def
				} else if (idx == 1) {
					this.btnChapter2BeginSrc = url_common_btn_enter_base_def
				}
			},
			onBtnEnter() {
				console.log('onBtnEnter')
				this.isShowWelcome = false
				this.isVideoPlay = true
				this.player = null
				this.skipAni = true
				this.player = document.querySelector('.video')
				// console.log('player: ', this.player)
				this.player.play()
				// 重置currentTime属性
				document.getElementById('video1').currentTime = 0
				if (this.timer3 != '') {
					clearTimeout(this.timer3)
				}
				this.timer3 = setTimeout(() => {
					this.isShowWelcome = true
					clearTimeout(this.timer3)
				}, 54000)
				this.pauseTimer = setTimeout(() => {
					this.player.pause()
					clearTimeout(this.pauseTimer)
					this.pauseTimer = null;
				}, 60000)
				this.$parent.$refs.music.pause()

				if (!this.isMute) {
					this.player.mute = true
				} else {
					this.player.mute = false
				}

				if (!this.isMute) {
					this.$refs.clickAudio.play()
				}
			},
			onBtnEnter2() {
				// console.log(this.firstIndex, 'firstIndex1111111')
				this.isShowVolIcon = true
				if (this.pauseTimer) {
					this.player.pause()
					clearTimeout(this.pauseTimer)
				}
				this.isVideoPlay = false
				this.isVideoPlay1 = true
				this.player = null
				this.isBegin = false
				this.isBegin2 = true
				this.isShowWelcome = false
				this.player = document.querySelector('.video1')
				this.player.play()
				// 重置currentTime属性
				document.getElementById('video2').currentTime = 0
				// this.firstIndex = localStorage.getItem('firstIndex') || -1
				if (this.firstIndex == -1) {
					let timer2 = setTimeout(() => {
						clearTimeout(timer2)
						this.isFirstLoad = false
						this.isBegin2 = false
						this.isShowWelcome = false
						this.firstIndex = 0
						this.swiperIndex = 0

						let timeout = 2000
						if (this.screenFlag == 3) {
							timeout = 1000
						}
						this.caseTimer0 = setTimeout(() => {
							this.page6CaseAppear(0)
							clearTimeout(this.caseTimer0)
						}, timeout)
						this.caseTimer1 = setTimeout(() => {
							this.page6CaseAppear(1)
							clearTimeout(this.caseTimer1)
						}, timeout + 1000)
						this.caseTimer2 = setTimeout(() => {
							this.page6CaseAppear(2)
							clearTimeout(this.caseTimer2)
						}, timeout + 2000)
						this.caseTimer3 = setTimeout(() => {
							this.page6CaseAppear(3)
							clearTimeout(this.caseTimer3)
						}, timeout + 3000)
						this.caseTimer4 = setTimeout(() => {
							this.page6CaseAppear(4)
							clearTimeout(this.caseTimer4)
						}, timeout + 4000)
						this.caseTimer5 = setTimeout(() => {
							this.page6CaseAppear(5)
							clearTimeout(this.caseTimer5)
						}, timeout + 5000)
						this.caseTimer6 = setTimeout(() => {
							this.page6CaseAppear(6)
							clearTimeout(this.caseTimer6)
						}, timeout + 6000)
						this.caseTimer7 = setTimeout(() => {
							this.page6CaseAppear(7)
							clearTimeout(this.caseTimer7)
						}, timeout + 7000)
						this.caseTimer8 = setTimeout(() => {
							this.page6CaseAppear(8)
							clearTimeout(this.caseTimer8)
						}, timeout + 8000)
						this.caseTimer9 = setTimeout(() => {
							this.page6CaseAppear(9)
							clearTimeout(this.caseTimer9)
						}, timeout + 9000)
						this.caseTimer10 = setTimeout(() => {
							this.page6CaseAppear(10)
							clearTimeout(this.caseTimer10)
						}, timeout + 10000)
					}, 9000)

					// localStorage.setItem('firstIndex', 0)
				} else {
					let timer2 = setTimeout(() => {
						this.isFirstLoad = false
						this.isBegin2 = false
						this.isShowWelcome = false
						this.swiperIndex = 0
						this.player.pause()


						this.page6CaseAppear1(0)
						this.page6CaseAppear1(1)
						this.page6CaseAppear1(2)
						this.page6CaseAppear1(3)
						this.page6CaseAppear1(4)
						this.page6CaseAppear1(5)
						this.page6CaseAppear1(6)
						this.page6CaseAppear1(7)
						this.page6CaseAppear1(8)
						this.page6CaseAppear1(9)
						this.page6CaseAppear1(10)
						clearTimeout(timer2)
					}, 9000) // 开门声音9秒，
				}
				if (!this.isMute) {
					this.$parent.$refs.music.play()
				} else {
					this.$parent.$refs.music.pause()
				}
				if (!this.isMute) {
					this.player.mute = true
				} else {
					this.player.mute = false
				}

				if (this.screenFlag != 3) {
					if (!this.isMute) {
						this.$refs.clickAudio.play()
					}
				}
			},
			videoPlayEnded() {
				console.log('videoPlayEnded')
				// this.isVideoPlay = false
				this.player.pause()

				if (this.isBegin && this.isEnd) {
					console.log("isBegin is true")
					this.isBegin = false

					this.isBegin2 = true
					this.swiperIndex = 0
					this.isVideoPlay = false
					this.isShowEnter = false

					// this.preloadImgsFunc()

					// this.chapter2NotionAppear("Welcome to Notionbase.")

				} else if (this.isBegin2 && this.isEnd) {
					console.log("isBegin2 is true")

					// this.isShowWelcome = false

					// let timeout = 2000
					// if (this.screenFlag == 3) {
					// 	timeout = 1000
					// }
					// this.caseTimer0 = setTimeout(() => {
					// 	this.page6CaseAppear(0)
					// 	clearTimeout(this.caseTimer0)
					// }, timeout)
					// this.caseTimer1 = setTimeout(() => {
					// 	this.page6CaseAppear(1)
					// 	clearTimeout(this.caseTimer1)
					// }, timeout + 1000)
					// this.caseTimer2 = setTimeout(() => {
					// 	this.page6CaseAppear(2)
					// 	clearTimeout(this.caseTimer2)
					// }, timeout + 2000)
					// this.caseTimer3 = setTimeout(() => {
					// 	this.page6CaseAppear(3)
					// 	clearTimeout(this.caseTimer3)
					// }, timeout + 3000)
					// this.caseTimer4 = setTimeout(() => {
					// 	this.page6CaseAppear(4)
					// 	clearTimeout(this.caseTimer4)
					// }, timeout + 4000)

					// let timer = setTimeout(() => {
					// 	this.isBegin2 = false

					// 	clearTimeout(timer)
					// }, 500)
				}
			},
			onBtnArrowDown() {
				this.$refs.pageSwiper.$swiper.slideTo(1, 1000, false)
			},
			page6CaseAppear(idx) {
				let that = this

				let speed = 50
				let count = 1

				let timer

				function changeContent() {
					if (idx == 0) {
						that.case0Title = that.casesArr[idx].title.substring(0, count)
					} else if (idx == 1) {
						that.case1Title = that.casesArr[idx].title.substring(0, count)
					} else if (idx == 2) {
						that.case2Title = that.casesArr[idx].title.substring(0, count)
					} else if (idx == 3) {
						that.case3Title = that.casesArr[idx].title.substring(0, count)
					} else if (idx == 4) {
						that.case4Title = that.casesArr[idx].title.substring(0, count)
					} else if (idx == 5) {
						that.case5Title = that.casesArr[idx].title.substring(0, count)
					} else if (idx == 6) {
						that.case6Title = that.casesArr[idx].title.substring(0, count)
					} else if (idx == 7) {
						that.case7Title = that.casesArr[idx].title.substring(0, count)
					} else if (idx == 8) {
						that.case8Title = that.casesArr[idx].title.substring(0, count)
					} else if (idx == 9) {
						that.case9Title = that.casesArr[idx].title.substring(0, count)
					} else if (idx == 10) {
						that.case10Title = that.casesArr[idx].title.substring(0, count)
					}

					count++

					if (count != that.casesArr[idx].title.length + 1) {
						speed -= 1
						if (speed < 5) speed = 5

						timer = setTimeout(changeContent, speed)
					} else {
						clearTimeout(timer)
					}
				}
				changeContent()
			},
			page6CaseAppear1(idx) {
				let that = this

				function changeContent() {
					if (idx == 0) {
						that.case0Title = that.casesArr[idx].title
					} else if (idx == 1) {
						that.case1Title = that.casesArr[idx].title
					} else if (idx == 2) {
						that.case2Title = that.casesArr[idx].title
					} else if (idx == 3) {
						that.case3Title = that.casesArr[idx].title
					} else if (idx == 4) {
						that.case4Title = that.casesArr[idx].title
					} else if (idx == 5) {
						that.case5Title = that.casesArr[idx].title
					} else if (idx == 6) {
						that.case6Title = that.casesArr[idx].title
					} else if (idx == 7) {
						that.case7Title = that.casesArr[idx].title
					} else if (idx == 8) {
						that.case8Title = that.casesArr[idx].title
					} else if (idx == 9) {
						that.case9Title = that.casesArr[idx].title
					} else if (idx == 10) {
						that.case10Title = that.casesArr[idx].title
					}
				}
				changeContent()
			},
			initParallax() {
				var page8 = document.getElementById('page8');
				var page8Instance = new Parallax(page8);
			},
			onBtnLogo() {
				console.log('onBtnLogo')
				if (!this.isShowMenu) {
					this.isShowMenu = true
				} else {
					this.isShowMenu = false
				}
				if (!this.isMute) {
					this.$refs.clickAudio.play()
				}
			},
			onMouseMenuOver() {
				console.log('onMouseMenuOver')
				this.isShowMenu = true
			},
			onMouseMenuOut() {
				console.log('onMouseMenuOut')
				this.isShowMenu = false
			},
			onBtnAudio() {
				this.isMute = !this.isMute
				if (this.isMute) {
					this.volumeSrc = url_menu_icon_mute
					this.$parent.$refs.music.pause()
				} else {
					this.volumeSrc = url_menu_icon_volume
					this.$parent.$refs.music.play()
				}
			},
			onBtnSelect(idx) {
				this.swiperIndex1 = -1
				console.log(this.swiperIndex, 'this.swiperIndex')
				// this.firstIndex = localStorage.getItem('firstIndex', this.firstIndex)
				// localStorage.setItem('firstIndex', this.firstIndex)
				console.log('onBtnSelect')
				console.log('pageSwiper： ', this.$refs.pageSwiper)

				// this.pageReset()
				this.isEnd = false

				if (idx == 1) {
					console.log(this.isMute, 'isMute')
					this.isBegin = true
					this.isBegin2 = false
					this.swiperIndex = 0
					this.isVideoPlay = false
					this.isShowEnter = false
					this.isShowWelcome = false
					this.isFirstLoad = false
					this.sloganStr1 = null
					this.sloganStr2 = null
					this.chapter1SloganAppear1("We Create Experience,")
					this.enterSrc = url_common_btn_start_exploring_def
					this.btnChapter2BeginSrc = url_common_btn_enter_base_def
					this.thanksOffset = 0
					this.thanksScrollEnd()
					this.isShowVolIcon = false
					this.$parent.$refs.music.pause()
					this.$refs.pageSwiper.$swiper.slideTo(0, 1000, false)
					let tim = setTimeout(() => {
					  this.swiperIndex1 = 0
						clearTimeout(tim)
					}, 700)
					// class="video1"是第二个视频
					let play1 = document.querySelector('.video1')
					play1.muted = true
				} else if (idx == 2) {
					this.isBegin = false
					this.isBegin2 = false
					this.swiperIndex = 0
					this.isVideoPlay = false
					this.isShowEnter = false
					this.isShowWelcome = false
					this.isShowVolIcon = true

					let timeout = 2000
					if (this.screenFlag == 3) {
						timeout = 1000
					}
					// this.caseTimer0 = setTimeout(() => {
					// 	this.page6CaseAppear(0)
					// 	clearTimeout(this.caseTimer0)
					// }, timeout)
					// this.caseTimer1 = setTimeout(() => {
					// 	this.page6CaseAppear(1)
					// 	clearTimeout(this.caseTimer1)
					// }, timeout + 1000)
					// this.caseTimer2 = setTimeout(() => {
					// 	this.page6CaseAppear(2)
					// 	clearTimeout(this.caseTimer2)
					// }, timeout + 2000)
					// this.caseTimer3 = setTimeout(() => {
					// 	this.page6CaseAppear(3)
					// 	clearTimeout(this.caseTimer3)
					// }, timeout + 3000)
					// this.caseTimer4 = setTimeout(() => {
					// 	this.page6CaseAppear(4)
					// 	clearTimeout(this.caseTimer4)
					// }, timeout + 4000)
					if (!this.isMute) {
						this.$parent.$refs.music.play()
					} else {
						this.$parent.$refs.music.pause()
					}

					this.$refs.pageSwiper.$swiper.slideTo(0, 1000, false)
					let tim1 = setTimeout(() => {
					  this.swiperIndex1 = 0
						clearTimeout(tim1)
					}, 700)
				} else if (idx == 3) {
					this.isBegin = false
					this.isBegin2 = false
					this.swiperIndex = 0
					this.isVideoPlay = false
					this.isShowEnter = false
					this.isShowWelcome = false
					this.isShowVolIcon = true

					this.thanksOffset = 0
					this.thanksScroll()
					if (!this.isMute) {
						this.$parent.$refs.music.play()
					} else {
						this.$parent.$refs.music.pause()
					}

					this.$refs.pageSwiper.$swiper.slideTo(1, 1000, false)
				} else if (idx == 4) {
					this.isBegin = false
					this.isBegin2 = false
					this.swiperIndex = 0
					this.isVideoPlay = false
					this.isShowEnter = false
					this.isShowWelcome = false
					this.isShowVolIcon = true
					if (!this.isMute) {
						this.$parent.$refs.music.play()
					} else {
						this.$parent.$refs.music.pause()
					}

					this.$refs.pageSwiper.$swiper.slideTo(2, 1000, false)
				}

				if (!this.isMute) {
					this.$refs.clickAudio.play()
				}
			},
			onMouseOut() {
				console.log('onMouseOut')
				this.currentMenuIndex = -1
			},
			onMouseOver(idx) {
				console.log('onMouseOver')
				this.currentMenuIndex = idx
			},
			onBtnCaseSelect(idx) {
				this.showCaseDetail = true
				this.caseIndex = idx
				this.player.pause()

				if (!this.isMute) {
					this.$refs.clickAudio.play()
				}
			},
			onMouseCaseOut() {
				console.log('onMouseCaseOut')
				if (this.showCaseDetail) return

				// this.caseIndex = -1
			},
			onMouseCaseOver(idx) {
				console.log('onMouseCaseOver')
				this.caseIndex = idx
				console.log(this.screenFlag, 'this.screenFlag')
				if (this.screenFlag == 3) {
					if (idx == 0) {
						this.selectCasePhoneSrc = url_case_hw_phone
					} else if (idx == 1) {
						this.selectCasePhoneSrc = url_case_dg_phone
					} else if (idx == 2) {
						this.selectCasePhoneSrc = url_case_valorant_phone
					} else if (idx == 3) {
						this.selectCasePhoneSrc = url_case_chaumet_phone
					} else if (idx == 4) {
						this.selectCasePhoneSrc = url_case_delvaux_phone
					} else if (idx == 5) {
						this.selectCasePhoneSrc = url_case_vw_phone
					} else if (idx == 6) {
						this.selectCasePhoneSrc = url_case_Tiffany_phone
					} else if (idx == 7) {
						this.selectCasePhoneSrc = url_case_Martell_phone
					} else if (idx == 8) {
						this.selectCasePhoneSrc = url_case_dior_phone
					} else if (idx == 9) {
						this.selectCasePhoneSrc = url_case_GM_phone
					} else if (idx == 10) {
						this.selectCasePhoneSrc = url_case_dickies_phone
					}
				} else {
					if (idx == 0) {
						this.selectCaseSrc = url_case_hw
					} else if (idx == 1) {
						this.selectCaseSrc = url_case_dg
					} else if (idx == 2) {
						this.selectCaseSrc = url_case_valorant
					} else if (idx == 3) {
						this.selectCaseSrc = url_case_chaumet
					} else if (idx == 4) {
						this.selectCaseSrc = url_case_delvaux
					} else if (idx == 5) {
						this.selectCaseSrc = url_case_vw
					} else if (idx == 6) {
						this.selectCaseSrc = url_case_Tiffany
					} else if (idx == 7) {
						this.selectCaseSrc = url_case_Martell
					} else if (idx == 8) {
						this.selectCaseSrc = url_case_dior
					} else if (idx == 9) {
						this.selectCaseSrc = url_case_GM
					} else if (idx == 10) {
						this.selectCaseSrc = url_case_dickies
					}
				}
			},
			thanksScroll() {
				console.log('thanksScroll')

				this.thansAnimFrame = window.requestAnimationFrame(this.update)
			},
			thanksScrollEnd() {
				console.log('thanksScrollEnd')
				if (this.thansAnimFrame != -1) {
					console.log('thans anim frame is not -1')
					window.cancelAnimationFrame(this.thansAnimFrame)
					this.thansAnimFrame = -1
				}
			},
			update() {
				if (this.swiperIndex == 1) {
					this.thansAnimFrame = window.requestAnimationFrame(this.update)

					if (this.screenFlag == 3) {
						this.thanksOffset += 1
					} else {
						this.thanksOffset += 2
					}
					// console.log(this.thanksOffset)
					if (this.thanksOffset > this.width * 0.64 * 8126 / 2614 + this.height * 0.82) {
						this.thanksScrollEnd()
					} else {
						document.getElementById('page7Content').scrollTop = this.thanksOffset
					}
				}
			},
			onMouseReplayOver() {
				// this.btnReplayOpacity = 0.6
				this.btnReplaySrc = url_common_btn_replay_select
			},
			onMouseReplayOut() {
				// this.btnReplayOpacity = 1
				this.btnReplaySrc = url_common_btn_replay
			},
			onMouseNextOver() {
				this.btnNextChptSrc = url_common_next_chpt_sel
			},
			onMouseNextOut() {
				this.btnNextChptSrc = url_common_next_chpt
			},
			onBtnNext() {
				this.$refs.pageSwiper.$swiper.slideTo(1, 1000, false)
				if (!this.isMute) {
					this.$refs.clickAudio.play()
				}
			},
			onBtnReplay() {
				this.thanksScrollEnd()
				this.thanksOffset = 0
				this.thanksScroll()

				this.isClickRefresh = true
				let timer = setTimeout(() => {
					this.isClickRefresh = false
					clearTimeout(timer)
				}, 500)

				if (!this.isMute) {
					this.$refs.clickAudio.play()
				}
			},
			onMouseSkipOver() {
				this.btnSkipOpacity = 1
			},
			onMouseSkipOut() {
				this.btnSkipOpacity = 0.8
			},

			onBtnSkipToEnd() {
				this.$refs.pageSwiper.$swiper.slideTo(2, 1000, false)
				if (!this.isMute) {
					this.$refs.clickAudio.play()
				}
			},
			onBtnSkipToEnd1() {
				this.isShowVolIcon = true
				this.isBegin = false
				this.isBegin2 = true
				this.swiperIndex = 0
				this.isVideoPlay = false
				this.isVideoPlay1 = true
				this.isShowEnter = false
				this.player = document.querySelector('.video1')
				this.player.play()
				// 重置currentTime属性
				document.getElementById('video2').currentTime = 0

				this.chapter2NotionAppear("Welcome to Notionbase.")
				let timer5 = setTimeout(() => {
					this.isFirstLoad = false
					this.isBegin2 = false
					this.isShowWelcome = false
					this.swiperIndex = 0
					this.player.pause()
					this.$refs.pageSwiper.$swiper.slideTo(0, 1000, false)

					this.page6CaseAppear1(0)
					this.page6CaseAppear1(1)
					this.page6CaseAppear1(2)
					this.page6CaseAppear1(3)
					this.page6CaseAppear1(4)
					clearTimeout(timer5)
				}, 9000) // 开门声音9秒，

				if (!this.isMute) {
					this.$parent.$refs.music.play()
				} else {
					this.$parent.$refs.music.pause()
				}

				if (!this.isMute) {
					this.player.mute = true
				} else {
					this.player.mute = false
				}

				if (!this.isMute) {
					this.$refs.clickAudio.play()
				}
			},
			onBtnDetailClose() {
				console.log("onBtnDetailClose")
				this.showCaseDetail = false
				this.isShowWechat = false
				this.isShowLinkedin = false
				this.isShowRedbook = false

				this.isShowAddressDetail = false

				this.isService = false
				this.isJobs = false
				this.isContact = false
				this.isShowMailBox = false

				this.caseDetailIndex = 0

				if (!this.isMute) {
					this.$refs.clickAudio.play()
				}
			},
			onBtnWechat() {
				console.log("onBtnWechat")
				this.isShowWechat = true
				this.qrcodeSrc = url_bottom_wechat

				if (!this.isMute) {
					this.$refs.clickAudio.play()
				}
			},
			onBtnLinkedin() {
				console.log("onBtnLinkedin")
				// this.isShowLinkedin = true
				window.open("https://www.linkedin.com/company/notionbase/")

				if (!this.isMute) {
					this.$refs.clickAudio.play()
				}
			},
			onBtnRedbook() {
				console.log("onBtnRedbook")
				this.isShowRedbook = true
				this.qrcodeSrc = url_bottom_redbook

				if (!this.isMute) {
					this.$refs.clickAudio.play()
				}
			},
			onBtnAddressDetail() {
				console.log("onBtnAddressDetail")
				this.isShowAddressDetail = true
				if (!this.isMute) {
					this.$refs.clickAudio.play()
				}
			},
			onMouseAddressDetailOver(idx) {
				this.mouseOnAddressIdx = idx
			},
			onMouseAddressDetailOut() {
				this.mouseOnAddressIdx = -1
			},
			onMouseSocialOver(idx) {
				this.mouseOnSocialIdx = idx
			},
			onMouseSocialOur() {
				this.mouseOnSocialIdx = -1
			},
			onMousSubmitOver() {
				// this.isMouseOnSubmit = true
				this.btnSubmitSrc = url_common_btn_submit_sel
			},
			onMousSubmitOut() {
				// this.isMouseOnSubmit = false
				this.btnSubmitSrc = url_common_btn_submit_def
			},
			// 点击弹窗外的区域关闭弹窗
			cancelPop(event) {
				let tp1 = document.querySelector(".service-detail")
				let tp2 = document.querySelector(".jobs-detail")
				let tp3 = document.querySelector(".submit-result")
				let tp4 = document.querySelector(".address-detail")
				let tp5 = document.querySelector(".social-detail")
				let tp6 = document.querySelector(".mail-detail")

				if (tp1) {
					if (!tp1.contains(event.target)) {
						this.isService = false
					}
				} else if (tp2) {
					if (!tp2.contains(event.target)) {
						this.isJobs = false
					}
				} else if (tp3) {
					if (!tp3.contains(event.target)) {
						this.isSubmit = false
					}
				} else if (tp4) {
					if (!tp4.contains(event.target)) {
						this.isShowAddressDetail = false
					}
				} else if (tp5) {
					if (!tp5.contains(event.target)) {
						this.isShowWechat = false
						this.isShowLinkedin = false
						this.isShowRedbook = false
					}
				} else if (tp6) {
					if (!tp6.contains(event.target)) {
						this.isShowMailBox = false
					}
				}
			},
			onBtnService() {
				this.isSelectService = true
				this.btnServiceSrc = url_common_btn_service_sel
				this.isSelectJobs = false
				this.btnJobsSrc = url_common_btn_jobs
				this.isSelectContact = false
				this.btnContactSrc = url_common_btn_contact
				this.page8RightSrc = url_index_computer
				if (!this.isMute) {
					this.$refs.clickAudio.play()
				}
			},
			onBtnJobs() {
				this.isSelectJobs = true
				this.btnJobsSrc = url_common_btn_jobs_sel
				this.isSelectService = false
				this.btnServiceSrc = url_common_btn_service
				this.isSelectContact = false
				this.btnContactSrc = url_common_btn_contact
				this.page8RightSrc = url_index_spaceman
				if (!this.isMute) {
					this.$refs.clickAudio.play()
				}
			},
			onBtnContact() {
				this.isSelectContact = true
				this.btnContactSrc = url_common_btn_contact_sel
				this.isSelectJobs = false
				this.btnJobsSrc = url_common_btn_jobs
				this.isSelectService = false
				this.btnServiceSrc = url_common_btn_service
				this.page8RightSrc = url_index_phone
				if (!this.isMute) {
					this.$refs.clickAudio.play()
				}
			},
			onMouseButtonOver(idx) {
				console.log(idx, 'idx')
				this.mIndex = idx;

				if (idx == 0) {
					console.log(this.mIndex, 'this.mIndex')
					this.btnArrowSrc0 = url_common_arrow_100
				} else if (idx == 1) {
					console.log(this.mIndex, 'this.mIndex')
					this.btnArrowSrc1 = url_common_arrow_100
				} else if (idx == 2) {
					console.log(this.mIndex, 'this.mIndex')
					this.btnArrowSrc2 = url_common_arrow_100
				}
			},
			onMouseButtonOut(idx) {
				// this.mIndex == -1
				this.mIndex == -1;
				if (idx == 0) {
					this.btnArrowSrc0 = url_common_arrow_70
				} else if (idx == 1) {
					this.btnArrowSrc1 = url_common_arrow_70
				} else if (idx == 2) {
					this.btnArrowSrc2 = url_common_arrow_70
				}
			},
			openServiceDetail(idx) {
				this.isService = true
				this.id = idx
				// console.log(this.id)
				// this.serviceText1 = this.serviceText[idx]
				// console.log(this.serviceText1, 'this.serviceText1222222')
				if (!this.isMute) {
					this.$refs.clickAudio.play()
				}
			},
			openJobsDetail(idx) {
				this.selectTitle = this.jobTitleList[idx].title
				this.selectTitleId = this.jobTitleList[idx].id
				this.contentList = this.jobContentList[this.jobTitleList[idx].id]
				this.isJobs = true
				if (!this.isMute) {
					this.$refs.clickAudio.play()
				}
			},
			onMouseApplyOver() {
				this.btnApplySrc = url_common_btn_apply
			},
			onMouseApplyOut() {
				this.btnApplySrc = url_common_btn_apply_def
			},
			onBtnApply() {
				this.isShowMailBox = true
				this.isJobs = false
				if (!this.isMute) {
					this.$refs.clickAudio.play()
				}
			},
			bindInputFullNameChange(e) {
				console.log("full name: ", this.fullNameStr)

				let mailReg = /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/ //邮箱正则

				if (this.fullNameStr.length > 0 &&
					this.emailStr.length > 0 &&
					this.tellUsStr.length > 0 &&
					mailReg.test(this.emailStr)) {
					this.isCanSubmit = true
				} else {
					this.isCanSubmit = false
				}
			},
			bindInputFullNameChange1(e) {
				console.log("full name: ", this.fullNameStr1)

				let mailReg = /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/ //邮箱正则

				if (this.fullNameStr1.length > 0 &&
					this.emailStr1.length > 0 &&
					this.tellUsStr1.length > 0 &&
					mailReg.test(this.emailStr1)) {
					this.isCanSubmit1 = true
				} else {
					this.isCanSubmit1 = false
				}
			},
			bindInputEmailChange(e) {
				console.log("Email: ", this.emailStr)

				let mailReg = /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/ //邮箱正则

				if (this.fullNameStr.length > 0 &&
					this.emailStr.length > 0 &&
					this.tellUsStr.length > 0 &&
					mailReg.test(this.emailStr)) {
					this.isCanSubmit = true
				} else {
					this.isCanSubmit = false
				}
			},
			bindInputEmailChange1(e) {
				console.log("Email: ", this.emailStr1)

				let mailReg = /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/ //邮箱正则

				if (this.fullNameStr1.length > 0 &&
					this.emailStr1.length > 0 &&
					this.tellUsStr1.length > 0 &&
					mailReg.test(this.emailStr1)) {
					this.isCanSubmit1 = true
				} else {
					this.isCanSubmit1 = false
				}
			},
			bindInputTellUsChange(e) {
				console.log("tellUsStr: ", this.tellUsStr)

				let mailReg = /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/ //邮箱正则

				if (this.fullNameStr.length > 0 &&
					this.emailStr.length > 0 &&
					this.tellUsStr.length > 0 &&
					mailReg.test(this.emailStr)) {
					this.isCanSubmit = true
				} else {
					this.isCanSubmit = false
				}
			},
			bindInputTellUsChange1(e) {
				console.log("tellUsStr: ", this.tellUsStr1)

				let mailReg = /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/ //邮箱正则

				if (this.fullNameStr1.length > 0 &&
					this.emailStr1.length > 0 &&
					this.tellUsStr1.length > 0 &&
					mailReg.test(this.emailStr1)) {
					this.isCanSubmit1 = true
				} else {
					this.isCanSubmit1 = false
				}
			},
			onBtnSubmit() {
				console.log("onBtnSubmit")
				if (this.isCanSubmit) {
					this.$axios.post("https://activity.notionbase.com/api/project/website/set-data", {
						/**
						 * 名称
						 */
						full_name: this.fullNameStr,
						/**
						 * 邮箱
						 */
						email: this.emailStr,
						/**
						 * 说明
						 */
						des: this.tellUsStr,
						/**
						 * 公司
						 */
						company: "无",
						/**
						 * 官网地址
						 */
						website: "无"
					}).then(res => {
						console.log('reaues res: ', res)
						if (res.data.status == 0) {
							console.log('success')
							this.isSubmit = true
						}

					})
				}

				if (!this.isMute) {
					this.$refs.clickAudio.play()
				}
			},
			// 申请职位提交
			onBtnSubmit1() {
				const headers = {
					"Content-Type": "multipart/form-data",
				}
				console.log(this.userFile)
				if (this.isCanSubmit1) {
					let params = new FormData();
					params.append("file", document.getElementById("inputFile").files[0])
					params.append("title", this.selectTitle);
					params.append("job_id", this.selectTitleId, );
					params.append("full_name", this.fullNameStr1, );
					params.append("email", this.emailStr1, );
					params.append("remarks", this.tellUsStr1, );
					this.$axios.post("https://activity.notionbase.com/api/project/website/set-job", params, {
						headers
					}).then(res => {
						console.log('job apply res: ', res)
						if (res.data.status == 0) {
							console.log('success')
							this.isSubmit = true
							this.isShowMailBox = false
						}
					})
				}

				if (!this.isMute) {
					this.$refs.clickAudio.play()
				}
			},
			onBtnConfirm() {
				console.log("onBtnConfirm")
				this.isSubmit = false

				if (!this.isMute) {
					this.$refs.clickAudio.play()
				}
			},
		},
	}
</script>

<style scoped>
	@import url("./css/style_global.css");
	@import url("./css/style_page1.css");
	@import url("./css/style_page2.css");
	@import url("./css/style_page3.css");
	@import url("./css/style_page4.css");
	@import url("./css/style_page5.css");
	@import url("./css/style_page6.css");
	@import url("./css/style_page7.css");
	@import url("./css/style_page8.css");
	@import url("./css/style_bottom.css");
	@import url("./css/style_detail.css");

	.container {
		width: 100%;
		height: 100%;

		color: #fff;
		background-color: #000;
		/* background: linear-gradient(#64159F, #271968, #3C0046); */
	}

	.page-swiper {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.page-swiper-slide {
		width: 100%;
		height: 100%;
	}

	::-webkit-scrollbar {
		width: 0px !important;
		height: 0px !important;
	}
	
	.fade-enter-active {
	  transition: opacity 0.05s
	}
	
	.fade-leave-active {
		transition: opacity 0.05s
	}
	.fade-enter-to, .fade-leave-to {
	  opacity: 0
	}
</style>
