const version = "?v=0.01"
export const g_casesArr = [
	{
		title: "HARRY WINSTON",
		detail: [{
				gif: 'https://qn.notionbase.com/notionbase/case/Hw/Hw(1).gif' + version,
				title: "RSVP ADMISSION",
				desc: "Guests can schedule appointments using the online mini-program, receive personalized invitations, and subsequently gain admission to the venue after on-site verification.",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/Hw/Hw(2).gif' + version,
				title: "INTERACTIVE BOOK",
				desc: "To dive into your desired story, simply choose it and gently rest your palm on the matching bookmark of the book for a quick 3 seconds. In a magical twist, the chapter you've picked will unfold right before your eyes! Wave your palm and watch the pages gracefully flip, you will reveal the next exciting page to read.",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/Hw/Hw(3).gif' + version,
				title: "PROJECTION MAPPING",
				desc: "Virtual tour of 5th Avenue through time, from 1960 Old New York to 2023 New York, Featuring Harry Winston voiceover and street sounds to immerse them in the bustling streets of New York and the story of Harry Winston Building.",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/Hw/Hw(4).gif' + version,
				title: "JEWELRY DIY EXPERIENCE",
				desc: "Guests select colors to fill in the sketch of Cluster products, then draw on the touch screen to create a signature and generate the final the postcard to download. ",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/Hw/Hw(5).gif' + version,
				title: "PHOTO INTERACTION",
				desc: "Inspired by the main installation in the Heritage Tunnel, this photo experience uses the design element of multiple layers and perspectives to create an artistic photo.",
			},
		]
	},
	{
		title: "D&G CIIE",
		detail: [{
				gif: 'https://qn.notionbase.com/notionbase/case/Dg/Dg(1).gif' + version,
				title: "DG 2023 CIIE INTERACTION",
				desc: "In November, Dolce&Gabbana once again participated in the 6th China International Import Expo. They showcased their brand products in 6 main exhibition areas and promoted a high-quality lifestyle. Notion Base developed a customized mini-program for the brand, consisting of 4 sections.",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/Dg/Dg(2).gif' + version,
				title: "VIRTUAL SHOWROOM",
				desc: "Guests enter the mini-program and discover the fascinating stories behind Dolce&Gabbana with CIIE. They can also access information about the six themed exhibition areas featured this year and admire the impressive craftsmanship displayed in both new and classic products.",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/Dg/Dg(3).gif' + version,
				title: "EXPLORE GAME & LUCKY DRAW",
				desc: "Onsite visitors can use the AR interactive section on the mini-program to discover and locate target products, and complete the collection game to take part in the lucky draw.",
			}
		]
	},
	{
		title: "VALORANT GAME",
		detail: [{
				gif: 'https://qn.notionbase.com/notionbase/case/Valorant/Valorant(1).png' + version,
				title: "VALORANT AGENT 24 LAUNCH",
				desc: "VALORANT held an Agent Launch Event in Chongqing in November. The event showcased the exciting introduction of the new Chinese agent, ISO, attracting numerous players to Chongqing to experience the thrill of offline battle games.",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/Valorant/Valorant(2).gif' + version,
				title: "PLAYER REGISTRATION",
				desc: "At the entrance, players scan the QR code to access our mini program, register for an event account, and confirm their game ID on the iPad.",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/Valorant/Valorant(3).gif' + version,
				title: "GAME SCORING",
				desc: "In the 1V1 Dome experience space, players log in to the event account and start the game.",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/Valorant/Valorant(4).gif' + version,
				title: "LEADERBOARD REFRESH",
				desc: "Play ISO to get on the Real-time leaderboard on the stairs! ",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/Valorant/Valorant(5).gif' + version,
				title: "TOP ISO",
				desc: "On the top, the winner's username in each round will appear on the big screen, where we see a grand projection of ISO, doing his iconic intro move.",
			}
		]
	},
	{
		title: "CHAUMET TIARA DREAM",
		detail: [{
				gif: 'https://qn.notionbase.com/notionbase/case/Chaumet/Chaumet(1).gif' + version,
				title: "VIRTUAL TRY-ON (VTO)",
				desc: "In the Fashion Room, Notion Base utilizes virtual try-on technology to enable guests to experience their own elegance by virtually wearing a crown through the screen.",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/Chaumet/Chaumet(2).gif' + version,
				title: "PHOTO CALL EXPERIENCE",
				desc: "At the end of the exhibition, there is an area where guests can see the mesmerizing moments of wearing the CHAUMET crown as the mirror flips.",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/Chaumet/Chaumet(3).gif' + version,
				title: "PALM PROJECTION",
				desc: "In the corner of the Transmission Room, Notion Base made a special interactive experience using projection and infrared sensing technology. Guests can feel the transmission story by placing their hands on the tabletop.",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/Chaumet/Chaumet(4).gif' + version,
				title: "TIARA DREAM MINI-PROGRAM",
				desc: "In addition to offline interactions, Notion Base has also customized a mini-program for this exhibition. Online Journey enables guests to enhance their understanding of the CHAUMET Tiara and participate in a treasure hunt throughout the exhibition. Meanwhile, guests who are unable to visit the exhibition in person can also experience and appreciate the timeless beauty of CHAUMET Tiaras through our mini-program.",
			}
		]
	},
	{
		title: "DELVAUX ART EXHIBITION",
		detail: [{
				gif: 'https://qn.notionbase.com/notionbase/case/Delvaux/Delvaux(1).png' + version,
				title: "DELVAUX IN THE NAME OF LOVE",
				desc: "This year's Qixi Festival, Delvaux integrates the imagination of René Magritte into reality to commemorate the 125th anniversary of the birth of the surrealist master and one of Belgium's greatest artists. The exhibition creates an exclusive immersive interactive art experience using multiple classic works and releases an Asian limited edition series, showcasing Delvaux's unwavering pursuit of art and its strong connection to Belgian culture.",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/Delvaux/Delvaux(2).gif' + version,
				title: "AI-POWERED ART EXHIBITION",
				desc: "Notion Base uses AI technology to digitize René Magritte's paintings and creates a vivid art narrative by linking two portraits in a dialogue, reproducing the classic works in front of the audience. Delvaux's artistic footprint will tour three major cities: Beijing, Hong Kong, Seoul and Tokyo.",
			}
		],
		detail2: [{
				gif: 'https://qn.notionbase.com/notionbase/case/Delvaux/Delvaux(3).png' + version,
				text: "HONG KONG STATION",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/Delvaux/Delvaux(4).png' + version,
				text: "KOREA STATION",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/Delvaux/Delvaux(5).png' + version,
				text: "BEIJING STATION",
			}
		]
	},
	{
		title: "VOLKSWAGEN SHAS",
		detail: [{
				gif: 'https://qn.notionbase.com/notionbase/case/Vw/Vw(1).gif' + version,
				title: "FUN AND CREATIVE",
				desc: "A customized photo installation was created exclusively for the Volkswagen brand. The first thing that catches the eye is the refreshing ID. Blue-themed set design. We integrated the Volkswagen ID. series star vehicles into four themed scenes, which were well-received by the attendees. Guests could not only save electronic versions of their photos but also choose to receive a printed copy as a keepsake.",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/Vw/Vw(2).gif' + version,
				title: "SAFETY AND POWER",
				desc: "In the Safety and Power area, Notion Base combined the Volkswagen brand's commitment to user safety with informative AR-enhanced interactive experiences. By scanning the markers in different scenes, guests could trigger safety features in the virtual environment.",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/Vw/Vw(3).gif' + version,
				title: "SMART AND SENSIBLE",
				desc: "This area showcased the ID. family series models, allowing guests to select different interactive boxes to learn about specific vehicle models and features, enhancing their interactive engagement.",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/Vw/Vw(4).gif' + version,
				title: "INTERACTIVE 3D SHOWROOM",
				desc: "In addition, our 3D online showroom featured detailed 360-degree views of the displayed cars, allowing users to explore the characteristics and performance of each model. Users could also save the photos taken in the interactive areas in their personal gallery for easy viewing and downloading.",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/Vw/Vw(5).gif' + version,
				title: "GIFT REDEMPTION",
				desc: "Guests could scan QR codes in different interactive areas to check in and track their progress, and upon completion, they could verify the QR code in the Gift Center to receive exquisite gifts.",
			}
		]
	},
	{
		title: "TIFFANY & CO",
		detail: [{
				gif: 'https://qn.notionbase.com/notionbase/case/Tiffany/Tiffany(1).gif',
				title: "RSVP",
				desc: "Inspired by New York street culture, we create a rich and interesting immersive event experience for Tiffany. The user arrives at the venue for identity verification and scans the QR code to obtain an exclusive invitation letter.",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/Tiffany/Tiffany(2).gif',
				title: "THE TIFFANY TIMES",
				desc: "Users are invited to shoot newspaper blockbusters in New York street scenes and get artistic photos of Tiffany Times through customized filters.",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/Tiffany/Tiffany(3).gif',
				title: "GIFT STATION",
				desc: "Users retrieve tailored gifts at the gift station by scanning the invitation codes. Different from the traditional vending machine, Notionbase can customize the digital process to enrich the user experience.",
			}
		]
	},
	{
		title: "MARTELL",
		detail: [{
				gif: 'https://qn.notionbase.com/notionbase/case/Martell/Martell(1).gif',
				title: "Journey begins",
				desc: "We customize an interactive tour for Martell. The whole process aims to stimulate the five senses and taste the extensive wine culture, leading users on a mysterious sensory journey.",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/Martell/Martell(2).gif',
				title: "Explore missing pieces",
				desc: "Users are guided through a series of interactions to complete the exploration of customized drinks. The interaction includes scanning the environmental canvas through AR technology for digital arts; lighting up the inspiration map through the sense of smell, and entering personal preferences on the mobile phone; tracking scent clues in the treasure house.",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/Martell/Martell(3).gif',
				title: "Your drink is ready!",
				desc: "After completing a series of interactions, users will receive a customized drink in the rest area, which is generated based on user preference data recorded during the journey.",
			}
		]
	},
	{
		title: "DIOR ADDICT",
		detail: [{
				gif: 'https://qn.notionbase.com/notionbase/case/Dior/CASE_VIDEO-DIOR(1).gif',
				title: "DIGITAL HUNT",
				desc: "We help Dior start a treasure hunt for lipsticks, and invite users to explore the exquisite world of Dior through the targeted positioning and scanning functions.",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/Dior/CASE_VIDEO-DIOR(2).gif',
				title: "LIPSTICK GIFTS",
				desc: "Users complete a series of real-scene explorations, virtual color try-on, and other functions through the mobile terminal, and get exclusive lipstick gifts.",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/Dior/CASE_VIDEO-DIOR(3).gif',
				title: "IMMERSIVE AREA",
				desc: "In order to realize Dior's vision of an immersive art space, Notionbase created a distinctive fantasy space, combined with trendy elements, and set karaoke as a video shooting point to enrich the interactive experience of guests.",
			}
		]
	},
	{
		title: "GENERAL MOTORS",
		detail: [{
				gif: 'https://qn.notionbase.com/notionbase/case/GM/GM(1).gif',
				title: "LIFESTYLE DOME",
				desc: "An immersive experience with the sensational audio-visual setting for General Motors. The concept of the flight cabin leads the audience to an immersive off-road experience, flying over the plains and hills to experience the beauty of nature.",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/GM/GM(2).gif',
				title: "PUSHING THE LIMITS",
				desc: "We pay tribute to the Corvette racing team and create an immersive simulated somatosensory interaction. Vibration motors are installed on the ground, and the compact and exciting racing scenes bring users a virtual experience of simulating the real racing field.",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/GM/GM(3).gif',
				title: "ME & MY COMMUNITY",
				desc: "A photo experience where guests are invited to take tailored portraits with Leica premium cameras and join the Durant Guild community by sharing it on the community wall.",
			}
		]
	},
	{
		title: "DICKIES ENERGY STORE",
		detail: [{
				gif: 'https://qn.notionbase.com/notionbase/case/Dickies/dickies(1).gif',
				title: "GLIMPSE",
				desc: "We made a screen-sensing interactive installation for the Dickies store entrance. By capturing the sensory dynamics of the guests, the installation triggers a trendy visual image, attracts customers to the brand, enriches retail contacts, and creatively delivers the brand cultural attitude of 「MADE IN DICKIES」 to the public.",
			},
			{
				gif: 'https://qn.notionbase.com/notionbase/case/Dickies/dickies(2).gif',
				title: "DETAILS BOOTH",
				desc: 'The Dickies x DHU x TMIC joint exhibition is inspired by the innovative design concept of "Generation Z" culture and features the works of 26 young designers. We empowers the brand with Digital Magnify Scope technology to make the exhibition fresh and interesting.',
			}
		]
	},
]